import actionTypes from './actionTypes';
import { ERROR, LOADED, LOADING, UNLOADED } from '../../constants/statuses';
import { AUTH_TOKEN_KEY } from '../../constants';

const initialState = {
    loginStatus: UNLOADED,
    userStatus: UNLOADED,
    isAuthenticated: !!localStorage.getItem(AUTH_TOKEN_KEY),
    accessToken: localStorage.getItem(AUTH_TOKEN_KEY),
    user: {},
    loginError: null,
    accountError: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_REQUEST:
            return {
                ...state,
                loginStatus: LOADING
            };
        case actionTypes.LOGIN_FAILURE:
            return {
                ...state,
                loginStatus: ERROR,
                loginError: action.error
            };
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                loginStatus: LOADED,
                isAuthenticated: true,
                loginError: action.error
            };
        case actionTypes.GET_ACCOUNT_REQUEST:
            return {
                ...state,
                userStatus: LOADING
            };
        case actionTypes.GET_ACCOUNT_SUCCESS:
            return {
                ...state,
                userStatus: LOADED,
                user: action.account
            };
        case actionTypes.GET_ACCOUNT_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                userStatus: ERROR,
                accountError: action.error
            };
        case actionTypes.UPDATE_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.accessToken
            }
        case actionTypes.LOGOUT:
            return {
                ...initialState,
                isAuthenticated: false
            };

        default:
            return state;
    }
};
