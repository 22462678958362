import actionTypes from './actionTypes';
import { ERROR, LOADED, LOADING, UNLOADED } from '../../../constants';

const initialState = {
    streamFetchStatus: UNLOADED,
    streamFetchError: null,
    stream: {},

    streamInfoFetchStatus: UNLOADED,
    streamInfoFetchError: null,
    streamInfo: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_STREAM_INFO:
            return {
                ...state,
                streamInfo: {
                    ...state.streamInfo,
                    count: action.count,
                    status: action.status,
                    playlistReady: action.playlistReady,
                    allowed: action.allowed
                },
                stream: {
                    ...state.stream,
                    playback: action.playback,
                }
            };
        case actionTypes.GET_STREAM_REQUEST:
            return {
                ...state,
                streamFetchStatus: LOADING
            };
        case actionTypes.GET_STREAM_FAILURE:
            return {
                ...state,
                streamFetchStatus: ERROR,
                streamFetchError: action.error
            };

        case actionTypes.GET_STREAM_SUCCESS:
            return {
                ...state,
                streamFetchStatus: LOADED,
                stream: action.stream
            };

        case actionTypes.GET_STREAM_INFO_REQUEST:
            return {
                ...state,
                streamInfoFetchStatus: LOADING
            };
        case actionTypes.GET_STREAM_INFO_FAILURE:
            return {
                ...state,
                streamInfoFetchStatus: ERROR,
                streamInfoFetchError: action.error
            };

        case actionTypes.GET_STREAM_INFO_SUCCESS:
            return {
                ...state,
                streamInfoFetchStatus: LOADED,
                streamInfo: action.stream
            };

        default:
            return state;
    }
};
