import { Route, Redirect } from 'react-router-dom';
import React from 'react';

import { connect } from 'react-redux';

import { hasAccessByRoles } from '../../../Util/api';
import { isAuthenticated } from '../../../redux/auth/selectors';

export const PrivateRoute = ({component: Component, path, roles, isAuthenticated, ...rest}) => {

    return (
        <Route
            {...rest}
            render={props => isAuthenticated && hasAccessByRoles(path, roles) ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{pathname: '/'}}/>
                )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: isAuthenticated(state)
});
const mapDispatchToProps = { };

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
