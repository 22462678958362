import actionTypes from './actionTypes';
import {ERROR, LOADED, LOADING, MESSAGES_DIRECTION_UP, UNLOADED} from '../../../constants';

const initialState = {
    streamKey: null,
    messagesAbove: true,

    messagesFetchStatus: UNLOADED,
    messagesFetchError: null,
    messages: [],

    sendMessageStatus: UNLOADED,
    sendMessageError: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MESSAGES_ABOVE:
            return {
                ...state,
                messagesAbove: action.messagesAbove
            }
        case actionTypes.SET_CURRENT_STREAM_KEY:
            return {
                ...state,
                streamKey: action.streamKey
            }
        case actionTypes.DELETE_MESSAGES:
            if (-1 !== state.messages.findIndex(m => action.deleted.includes(m.id))) {
                return {
                    ...state,
                    messages: [
                        ...state.messages.filter(m => !action.deleted.includes(m.id))
                    ]
                }
            } else {
                return state;
            }
        case actionTypes.ADD_MESSAGES:
            if (action.messages.length === 0) {
                return state;
            } else {
                return {
                    ...state,
                    messages: action.direction === MESSAGES_DIRECTION_UP ? [
                        ...action.messages,
                        ...state.messages
                    ] : [
                        ...state.messages,
                        ...action.messages
                    ]
                }
            }
        case actionTypes.GET_MESSAGES_REQUEST:
            return {
                ...state,
                messagesFetchStatus: LOADING
            };
        case actionTypes.GET_MESSAGES_FAILURE:
            return {
                ...state,
                messagesFetchStatus: ERROR,
                messagesFetchError: action.error
            };

        case actionTypes.GET_MESSAGES_SUCCESS:
            return {
                ...state,
                messagesFetchStatus: LOADED
            };

        case actionTypes.SEND_MESSAGE_REQUEST:
            return {
                ...state,
                sendMessageStatus: LOADING
            };
        case actionTypes.SEND_MESSAGE_FAILURE:
            return {
                ...state,
                sendMessageStatus: ERROR,
                sendMessageError: action.error
            };

        case actionTypes.SEND_MESSAGE_SUCCESS:
            return {
                ...state,
                sendMessageStatus: LOADED
            };

        default:
            return state;
    }
};
