import React, { useState } from 'react';

import DataTable from '../../../../Components/Organisms/DataTable/DataTable';
import Modal from '../../../../Components/Molecules/Modal/Modal';
import ConfirmModal from '../../../../Components/Molecules/ConfirmModal/ConfirmModal';

import CreateUserForm from '../../../../Components/Organisms/Users/CreateUserForm/CreateUserForm';
import EditUserForm from '../../../../Components/Organisms/Users/EditUserForm/EditUserForm';

import { formatDate } from '../../../../Util';
import PasswordColumn from '../../../../Components/Molecules/PasswordColumn/PasswordColumn';

const columns = [
    {title: 'Name', field: 'name'},
    {title: 'Login', field: 'login'},
    {
        title: 'Password',
        field: 'password',
        render: rowData => <PasswordColumn value={rowData.password} id={rowData.id} />
    },
    {title: 'Active', field: 'active', type: 'boolean'},
    {title: 'Updated at', field: 'updatedAt', type: 'date', render: rowData => formatDate(rowData.updatedAt)},
    {title: 'Created at', field: 'createdAt', type: 'date', render: rowData => formatDate(rowData.createdAt)}
];

function UsersList(props) {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [deleteRow, setDeleteRow] = useState(null);
    const [editRow, setEditRow] = useState(null);

    const {
        users,
        onHandleCreate,
        onHandleEdit,
        onHandleDelete
    } = props;

    const onAdd = () => {
        setCreateModalOpen(true);
    }

    const onDelete = (row) => {
        setDeleteRow(row);
        setDeleteModalOpen(true);
    }

    const onEdit = (row) => {
        setEditRow(row);
        setEditModalOpen(true);
    }

    const handleCreate = (data) => {
        onHandleCreate(data);
        setCreateModalOpen(false);
    }

    const handleEdit = (data) => {
        onHandleEdit(data);
        setEditModalOpen(false);
    }

    const handleDelete = () => {
        onHandleDelete(deleteRow);
        setDeleteModalOpen(false);
    }

    return (<div className={props.className || ''}>
            <DataTable
                tableType='Viewer'
                title={'Users'}
                columns={columns}
                data={users}
                onNew={onAdd}
                onDelete={onDelete}
                onEdit={onEdit}
            />

            {/* Create modal */}
            <Modal open={createModalOpen}
                   setOpen={setCreateModalOpen}
                   title={'Create user'}
            >
                <CreateUserForm onSubmit={handleCreate}/>
            </Modal>

            {/*Edit modal */}
            <Modal open={editModalOpen}
                   setOpen={setEditModalOpen}
                   title={'Edit user'}
            >
                <EditUserForm onSubmit={handleEdit} data={editRow}/>
            </Modal>

            {/* Delete confirm modal */}
            <ConfirmModal open={deleteModalOpen}
                          setOpen={setDeleteModalOpen}
                          title={'Delete user'}
                          description={'Are you sure you want to delete this user?'}
                          onSubmit={handleDelete}
            />
        </div>
    );
}

export default UsersList;
