import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { FormControl, InputAdornment, OutlinedInput } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const CreateUserForm = (props) => {
    const { onSubmit } = props;
    const [name, setName] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [active, setActive] = useState(false);

    const handleSubmit = () => {
        const data = { name, login, password, active };
        if (onSubmit) {
            onSubmit(data);
        }
    }

    const handleFieldChange = (setValue, event) => {
        setValue(event.target.value);
    }

    const handleCheckboxChange = (setValue, event) => {
        setValue(event.target.checked);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                autoFocus
                value={name}
                onChange={(event) =>
                    handleFieldChange(setName, event)}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="login"
                label="Login"
                name="login"
                autoComplete="login"
                autoFocus
                value={login}
                onChange={(event) =>
                    handleFieldChange(setLogin, event)}
            />
            <FormControl fullWidth variant="outlined" required>
                <OutlinedInput
                    required
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(event) =>
                        handleFieldChange(setPassword, event)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                            >
                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <FormControlLabel
                control={<Checkbox onChange={(event) =>
                    handleCheckboxChange(setActive, event)}
                                   value={active} checked={active} color="primary"/>}
                label="Enabled"
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                Create
            </Button>
        </div>
    );
}

export default CreateUserForm;
