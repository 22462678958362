import { SnackbarProvider } from 'notistack';

import Application from './Containers/Application';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home/Home';
import DefaultLayout from './Pages/Layout/Default/DefaultLayout';
import Notifier from './Pages/Layout/Notifier/Notifier';

import './App.scss';
import Viewer from './Pages/Viewer/Viewer';

function App() {
    return (
        <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            <Notifier/>
            <DefaultLayout>
                <Switch>
                    {/* Viewer functionality */}
                    <Route path="/app/online-stream/:streamKey" exact>
                        <Viewer/>
                    </Route>

                     {/*Application admin functionality*/}
                    <Route path={'/admin'}>
                        <Application/>
                    </Route>

                    <Route path="/" exact>
                        <Home/>
                    </Route>

                    <Redirect key='home_redirect' to={'/'}/>
                </Switch>
            </DefaultLayout>
        </SnackbarProvider>
    );
}


export default App;
