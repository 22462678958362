const actionTypes = {
    BAN_USER_REQUEST: 'chat/BAN_USER_REQUEST',
    BAN_USER_SUCCESS: 'chat/BAN_USER_SUCCESS',
    BAN_USER_FAILURE: 'chat/BAN_USER_FAILURE',

    DELETE_MESSAGE_REQUEST: 'chat/DELETE_MESSAGE_REQUEST',
    DELETE_MESSAGE_SUCCESS: 'chat/DELETE_MESSAGE_SUCCESS',
    DELETE_MESSAGE_FAILURE: 'chat/DELETE_MESSAGE_FAILURE',

    GET_BANNED_VIEWERS_REQUEST: 'chat/GET_BANNED_VIEWERS_REQUEST',
    GET_BANNED_VIEWERS_SUCCESS: 'chat/GET_BANNED_VIEWERS_SUCCESS',
    GET_BANNED_VIEWERS_FAILURE: 'chat/GET_BANNED_VIEWERS_FAILURE',
};

export default actionTypes;
