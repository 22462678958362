import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth/reducer';
import admin from './admin/reducer';
import viewer from './viewer/reducer';
import ui from './ui/reducer';

const rootReducer = (history) => combineReducers(
    {
        router: connectRouter(history),
        auth,
        admin,
        viewer,
        ui
    }
);

export default rootReducer;
