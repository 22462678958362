import React from 'react';
import { makeStyles, Paper, Typography } from '@material-ui/core';

import bannerTextImage from '../../../assets/images/banner-text.png';
import bannerImage from '../../../assets/images/banner-image.png';
import { APP_NAME } from '../../../constants';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    banner: {
        background: 'linear-gradient(90deg, ' + blue[600] + ' 19%, rgba(255, 255, 255, 1) 82%)',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '850px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '450px',
            flexDirection: 'column',
            background: 'linear-gradient(180deg, ' + blue[600] + ' 19%, rgba(255, 255, 255, 1) 82%)',
        }
    },
    bannerImg: {
        flexBasis: '40%',
        alignSelf: 'center',
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2)
        }
    },
    bannerImgImage: {
        maxWidth: '100%'
    },
    bannerText: {
        padding: theme.spacing(1),
        flexBasis: '25%',
        alignSelf: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '250px',
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    bannerTextImg: {
        maxWidth: '100%'
    },
    description: {
        flexBasis: '35%',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        alignSelf: 'center',
        padding: theme.spacing(1),
        color: blue[700],
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        }
    }
}));

export default function Banner(props) {
    const { text } = props;

    const classes = useStyles();

    return <Paper elevation={3} className={classes.banner}>
        <div className={classes.bannerText}>
            <img className={classes.bannerTextImg} src={bannerTextImage} alt={APP_NAME}/>
        </div>
        <div className={classes.bannerImg}>
            <img className={classes.bannerImgImage} src={bannerImage} alt={APP_NAME}/>
        </div>
        <Typography className={classes.description} variant={'h5'}>
            {text}
        </Typography>
    </Paper>;
}
