import axios from 'axios';
import actionTypes from './actionTypes';
import { hideLoader } from '../../ui/loader';
import { enqueueSnackbar } from '../../ui/notifications';
import { createErrorNotification, getErrorMessage } from '../../../Util';
import {LOADING, MESSAGES_DIRECTION_DOWN, MESSAGES_DIRECTION_UP} from '../../../constants';
import Moment from 'moment';
import {
    getCurrentStreamKey,
    getMessages,
    getMessagesFetchStatus,
    isMessagesAbove
} from './selectors';

const API_URL = 'api/streams';

export const formatMessagesTimestamp = (date) => Moment(date);

let currentNextCancelToken = null;

export const setChatStreamKey = streamKey => ({
    type: actionTypes.SET_CURRENT_STREAM_KEY,
    streamKey
})

export const fetchMessagesSuccess = () => dispatch => {
    dispatch({
        type: actionTypes.GET_MESSAGES_SUCCESS,
    });
}

export const fetchMessagesFailure = error => dispatch => {
    dispatch({
        type: actionTypes.GET_MESSAGES_FAILURE,
        error
    });

    if (typeof error !== 'string' && !error.message) {
        error = 'Невідома помилка. Спробуйте ще раз.';
    }

    dispatch(enqueueSnackbar(createErrorNotification(error)));
    dispatch(hideLoader());
}

export const sendMessageSuccess = (message) => dispatch => {
    dispatch({
        type: actionTypes.SEND_MESSAGE_SUCCESS,
        message
    });
    dispatch(fetchNextMessages(true));
}

export const sendMessageFailure = error => dispatch => {
    dispatch({
        type: actionTypes.SEND_MESSAGE_FAILURE,
        error
    });
    dispatch(enqueueSnackbar(createErrorNotification(error)));
}

const deleteMessages = deleted => ({
    type: actionTypes.DELETE_MESSAGES,
    deleted
});

const addMessages = (messages, direction) => ({
    type: actionTypes.ADD_MESSAGES,
    messages,
    direction
});

const setMessagesAbove = messagesAbove => ({
    type: actionTypes.SET_MESSAGES_ABOVE,
    messagesAbove
})

export const fetchMessagesByStreamViewKey = (direction, timestamp, cancelToken) => (dispatch, getState) => {
    if (!isMessagesAbove(getState()) && direction === MESSAGES_DIRECTION_UP) {
        return;
    }

    dispatch({
        type: actionTypes.GET_MESSAGES_REQUEST
    });

    const streamKey = getCurrentStreamKey(getState());
    const requestTimestamp = formatMessagesTimestamp(timestamp);

    return axios
        .get(`${API_URL}/${streamKey}/chat/messages/${direction}/${requestTimestamp.toISOString()}`, {
            cancelToken
        })
        .then(res => {
            dispatch(fetchMessagesSuccess());

            res.data.deleted.length > 0 && dispatch(deleteMessages(res.data.deleted));

            if (res.data.messages.length === 0) {
                direction === MESSAGES_DIRECTION_UP && dispatch(setMessagesAbove(false));
            } else {
                dispatch(addMessages(res.data.messages, direction));
            }
        })
        .catch(error => {
            if (error.toString && error.toString() === 'Cancel') {
                return;
            }

            dispatch(fetchMessagesFailure(getErrorMessage(error)));
        });
};

export const fetchNextMessages = (force) => (dispatch, getState) => {
    if (force && currentNextCancelToken) {
        currentNextCancelToken.cancel();
    }

    if (!force && getMessagesFetchStatus(getState()) === LOADING) {
        return;
    }

    currentNextCancelToken = axios.CancelToken.source();

    const messages = getMessages(getState());
    const timestamp = messages.length === 0 ? new Date().toISOString() : messages[messages.length - 1].createdAt;

    return dispatch(fetchMessagesByStreamViewKey(MESSAGES_DIRECTION_DOWN, timestamp, currentNextCancelToken.token));
}

let prevRequestStarted = false;

export const fetchPreviousMessages = () => (dispatch, getState) => {
    if (prevRequestStarted) {
        return;
    }

    prevRequestStarted = true;

    const messages = getMessages(getState());
    const timestamp = messages.length === 0 ? new Date().toISOString() : messages[0].createdAt;

    return dispatch(fetchMessagesByStreamViewKey(MESSAGES_DIRECTION_UP, timestamp))?.then((result) => {
        prevRequestStarted = false;
        return result;
    });
}

export const sendMessage = (payload) => (dispatch, getState) => {
    const streamKey = getCurrentStreamKey(getState());

    dispatch({
        type: actionTypes.SEND_MESSAGE_REQUEST
    });

    axios
        .post(`${API_URL}/${streamKey}/chat/messages`, payload)
        .then(response => dispatch(sendMessageSuccess(response.data)))
        .catch(error => dispatch(sendMessageFailure(getErrorMessage(error))));
};
