import './Error.scss';
import error from '../../../assets/images/error.png';
import { Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    errorTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
    },
    errorIcon: {
        marginLeft: theme.spacing(1),
        color: 'red'
    },
}));

function Error(props) {
    const { message } = props;
    const classes = useStyles();

    return (
        <div className="Error">
            <header className="Error-header">
                <img src={error} className="error-image" alt="logo" />
                <div className={classes.errorTitle}>
                    <Typography variant={'h4'}>
                        Упс! Щось пішло не так!
                    </Typography>
                    <WarningIcon className={classes.errorIcon}/>
                </div>

                <Typography variant={'h6'}>
                    {message}
                </Typography>
            </header>
        </div>
    );
}

export default Error;
