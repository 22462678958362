import axios from 'axios';
import actionTypes from './actionTypes';
import { hideLoader, showLoader } from '../../ui/loader';
import { enqueueSnackbar } from '../../ui/notifications';
import { createErrorNotification, createSuccessNotification, getErrorMessage } from '../../../Util';

const API_URL_PREFIX = 'api/streams';
const API_URL_SUFFIX = 'chat';

export const banUserSuccess = (streamKey, unban) => dispatch => {
    dispatch({
        type: actionTypes.BAN_USER_SUCCESS
    });
    dispatch(enqueueSnackbar(createSuccessNotification(
        !unban ? 'Користувача успішно заблоковано.' : 'Користувача успішно розблоковано.')));
    dispatch(fetchBannedViewers(streamKey));
    dispatch(hideLoader());
}

export const banUserFailure = error => dispatch => {
    dispatch({
        type: actionTypes.BAN_USER_FAILURE,
        error
    });
    dispatch(enqueueSnackbar(createErrorNotification(error)));
    dispatch(hideLoader());
}

export const deleteMessageSuccess = () => dispatch => {
    dispatch({
        type: actionTypes.DELETE_MESSAGE_SUCCESS
    });
    dispatch(enqueueSnackbar(createSuccessNotification('Повідомлення успішно видалено!')));
    dispatch(hideLoader());
}

export const deleteMessageFailure = error => dispatch => {
    dispatch({
        type: actionTypes.DELETE_MESSAGE_FAILURE,
        error
    });
    dispatch(enqueueSnackbar(createErrorNotification(error)));
    dispatch(hideLoader());

}

export const fetchBannedViewersSuccess = (bannedViewers) => dispatch => {
    dispatch({
        type: actionTypes.GET_BANNED_VIEWERS_SUCCESS,
        bannedViewers
    });
    dispatch(hideLoader());
}

export const fetchBannedViewersFailure = error => dispatch => {
    dispatch({
        type: actionTypes.GET_BANNED_VIEWERS_FAILURE,
        error
    });
    dispatch(enqueueSnackbar(createErrorNotification(error)));
}

export const banUser = (streamViewKey, payload) => dispatch => {
    dispatch({
        type: actionTypes.BAN_USER_REQUEST
    });
    dispatch(showLoader());

    axios
        .post(`/${API_URL_PREFIX}/${streamViewKey}/${API_URL_SUFFIX}/throttle/ban`, payload)
        .then(response => dispatch(banUserSuccess(streamViewKey, payload.unban)))
        .catch(error => dispatch(banUserFailure(getErrorMessage(error))));
};

export const deleteMessage = (streamViewKey, messageId) => dispatch => {
    dispatch({
        type: actionTypes.DELETE_MESSAGE_REQUEST
    });
    dispatch(showLoader());

    axios
        .delete(`/${API_URL_PREFIX}/${streamViewKey}/${API_URL_SUFFIX}/messages/${messageId}`)
        .then(response => dispatch(deleteMessageSuccess()))
        .catch(error => dispatch(deleteMessageFailure(getErrorMessage(error))));
};

export const fetchBannedViewers = (streamViewKey) => dispatch => {
    dispatch({
        type: actionTypes.GET_BANNED_VIEWERS_REQUEST
    });
    dispatch(showLoader());

    return axios
        .get(`/${API_URL_PREFIX}/${streamViewKey}/${API_URL_SUFFIX}/banned`)
        .then(res => dispatch(fetchBannedViewersSuccess(res.data)))
        .catch(error => dispatch(fetchBannedViewersFailure(getErrorMessage(error))));
};
