import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { grey, red } from '@material-ui/core/colors';
import { Videocam, YouTube } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { DEFAULT_PLAYER, YOUTUBE, YOUTUBE_PLAYER } from '../../../../../../constants';
import Modal from '../../../../../../Components/Molecules/Modal/Modal';
import PlayerFallbackSettingsEdit from './PlayerFallbackSettingsEdit';

import { Warning as WarningIcon, PowerSettingsNew as PowerOffIcon } from '@material-ui/icons';
import { Switch } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(3),
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    warningIcon: {
        color: red[500]
    },
    warningText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start'
    }
}));

export default function PlayerFallbackSettings(props) {
    const classes = useStyles();
    const { stream, updateStream } = props;
    const fallback = stream.fallback;

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [enabledReservePlayer, setEnabledReservePlayer] = useState(stream.fallbackEnabled || false);

    const getPlayerIcon = () => {
        if (!fallback) {
            return <Videocam/>;
        }
        switch (fallback.type) {
            case YOUTUBE:
                return <YouTube/>;
            default:
                return <Videocam/>;
        }
    }

    const getPlayerColor = () => {
        if (!fallback) {
            return grey[500];
        }
        switch (fallback.type) {
            case YOUTUBE:
                return red[500];
            default:
                return grey[500];
        }
    }

    const getPlayer = () => {
        if (!fallback || !fallback.type) {
            return DEFAULT_PLAYER;
        }
        switch (fallback.type) {
            case YOUTUBE:
                return YOUTUBE_PLAYER;
        }
    }

    const handleEditClick = () => {
        setEditModalOpen(true);
    }

    const handleToggleReservePlayerEnabled = () => {
        const value = !enabledReservePlayer;
        setEnabledReservePlayer(value);
        updateStream({
            ...stream,
            fallbackEnabled: value
        })
    }

    const handleEditSubmit = (data) => {
        updateStream(data);
        setEditModalOpen(false);
    }

    return (
        <>
            {/* Enable / disable reserve player */}
            <Card className={classes.root}>
                <CardHeader
                    avatar={
                        <Avatar style={{backgroundColor: enabledReservePlayer ? red[500] : grey[500]}}
                                aria-label="source" className={classes.avatar}>
                            <PowerOffIcon/>
                        </Avatar>
                    }
                    action={
                        <FormControlLabel
                            control={<Switch checked={enabledReservePlayer}
                                             onChange={handleToggleReservePlayerEnabled}
                                             name={'enabled'} />}
                            label={enabledReservePlayer ? 'Ввімкнений' : 'Вимкнений'}
                        />
                    }
                    title="Ввімкнути або вимкнути резервний програвач"
                />
                <CardContent>
                    <Typography className={classes.warningText} variant="body1" color="textSecondary" component="p">
                        <WarningIcon className={classes.warningIcon}/>{' '}
                        Увага! Редагування ввімкнення або вимкнення програвача змінює програвач для усіх глядачів стріму у реальному часі.
                    </Typography>
                </CardContent>
            </Card>

            {/* Settings for reserve player */}
            <Card className={classes.root}>
                <CardHeader
                    avatar={
                        <Avatar style={{backgroundColor: getPlayerColor()}}
                                aria-label="source" className={classes.avatar}>
                            {getPlayerIcon()}
                        </Avatar>
                    }
                    action={
                        <IconButton onClick={handleEditClick}>
                            <EditIcon/>
                        </IconButton>
                    }
                    title="Налаштування резервного програвача"
                    subheader={getPlayer()}
                />
                <CardContent>
                    <Typography variant="body1" color="textSecondary" component="p">
                        Резервний програвач дозволяє замінити вбудований на будь-який інший для зміни програвача
                        стрімінгу
                    </Typography>
                </CardContent>
            </Card>

            {/* Edit modal */}
            <Modal open={editModalOpen}
                   setOpen={setEditModalOpen}
                   title={'Редагувати резервний програвач'}
            >
                <PlayerFallbackSettingsEdit data={stream}
                                            onSubmit={handleEditSubmit}/>
            </Modal>
        </>
    );
}
