import React, {useState} from 'react';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { FormControl } from '@material-ui/core';
import { getISODate, getUTCISODate } from '../../../../Util';
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const EditStreamForm = (props) => {
    const { onSubmit, data } = props;
    const [name, setName] = useState(data.name || '');
    const [description, setDescription] = useState(data.description || '');
    const [enabled, setEnabled] = useState(data.enabled || false);
    const [allowed, setAllowed] = useState(data.allowed || false);
    const [startsAt, setStartsAt] = useState(
        getISODate(data.startsAt ? new Date(data.startsAt) : new Date()));

    const handleSubmit = () => {
        const result = {
            id: data.id,
            name,
            enabled,
            allowed,
            startsAt: getUTCISODate(startsAt),
            description
        };
        if (onSubmit) {
            onSubmit(result);
        }
    }

    const handleFieldChange = (setValue, event) => {
        setValue(event.target.value);
    }

    const handleCheckboxChange = (setValue, event) => {
        setValue(event.target.checked);
    }

    const handleStartsAtChange = (date) => {
        setStartsAt(date);
    };

    return (
        <div>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Назва"
                name="name"
                autoComplete="name"
                autoFocus
                value={name}
                onChange={(event) =>
                    handleFieldChange(setName, event)}
            />
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    variant={'outlined'}
                    margin="normal"
                    id="date-picker-dialog"
                    label="Дата початку"
                    format="dd.MM.yyyy"
                    value={startsAt}
                    onChange={handleStartsAtChange}
                    KeyboardButtonProps={{
                        'aria-label': 'Змінити дату',
                    }}
                />
                <KeyboardTimePicker
                    variant={'outlined'}
                    margin="normal"
                    id="time-picker"
                    label="Час початку"
                    value={startsAt}
                    onChange={handleStartsAtChange}
                    KeyboardButtonProps={{
                        'aria-label': 'Змінити час',
                    }}
                />
            </MuiPickersUtilsProvider>
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="description"
                label="Опис"
                name="name"
                autoComplete="description"
                autoFocus
                multiline
                rows={4}
                value={description}
                onChange={(event) =>
                    handleFieldChange(setDescription, event)}
            />
            <FormControl fullWidth>
                <FormControlLabel
                    control={<Checkbox onChange={(event) =>
                        handleCheckboxChange(setEnabled, event)}
                                       value={enabled} checked={enabled} color="primary"/>}
                    label="Ввімкнений"
                />
            </FormControl>
            <FormControl fullWidth>
                <FormControlLabel
                    control={<Checkbox onChange={(event) =>
                        handleCheckboxChange(setAllowed, event)}
                                       value={allowed} checked={allowed} color="primary"/>}
                    label="Дозволено запускати стрім для глядачів"
                />
            </FormControl>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                Редагувати
            </Button>
        </div>
    );
}

export default EditStreamForm;
