import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Modal from '../../../../Components/Molecules/Modal/Modal';
import ConfirmModal from '../../../../Components/Molecules/ConfirmModal/ConfirmModal';
import EditStreamForm from '../../../../Components/Organisms/Streams/EditStreamForm/EditStreamForm';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    IconButton,
    makeStyles,
    Typography
} from '@material-ui/core';

import { LOADED } from '../../../../constants';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { formatLocaleDate } from '../../../../Util';

import { getViewers, getViewersFetchStatus } from '../../../../redux/admin/viewers/selectors';
import { createViewer, deleteViewer, fetchViewers } from '../../../../redux/admin/viewers/actions';
import ViewersList from '../../../../Components/Organisms/Viewers/ViewersList/ViewersList';

const useStyles = makeStyles((theme) => ({
    padding: {
        padding: theme.spacing(5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    actions: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    links: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(5),
        alignItems: 'flex-end'
    },
    urlButton: {
        marginBottom: theme.spacing(1)
    },
    usersTable: {
        width: '100%',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    },
    tableName: {
        marginTop: theme.spacing(3)
    },
    enabledChip: {
        marginLeft: theme.spacing(1)
    },
    textValue: {
        color: theme.palette.grey[600]
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    accordion: {
        width: '100%',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    }
}));

function CommonDetailTab(props) {
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [viewersExpanded, setViewersExpanded] = useState(false);

    const history = useHistory();

    const {
        stream,
        viewers,
        viewersFetchStatus,
        handleEditClick,
        handleDeleteClick
    } = props;

    const { streamId } = useParams();

    const classes = useStyles();

    useEffect(() => {
        props.fetchViewers(streamId);
    }, []);

    const onDelete = () => {
        setDeleteModalOpen(true);
    }

    const onEdit = () => {
        setEditModalOpen(true);
    }

    const handleEdit = (data) => {
        const {...payload } = data;
        handleEditClick(payload);
        setEditModalOpen(false);
    }

    const handleDelete = () => {
        handleDeleteClick();
        setDeleteModalOpen(false);
        handleBack();
    }

    // Go back to streams page
    const handleBack = () => {
        history.push(`/admin/streams`);
    }

    const handleUserCreate = (data) => {
        props.createViewer(streamId, data);
    }

    const handleUserDelete = (data) => {
        props.deleteViewer(streamId, data.code);
    }

    const handleViewersExpanded = () => {
        setViewersExpanded(!viewersExpanded);
    }

    return <>
        <Grid container spacing={3}>
            {/* Stream info */}
            <Grid item xs={10}>
                {stream.description && <Typography variant={'subtitle1'} component={'h5'}>
                    Опис: <span className={classes.textValue}>
                            {stream.description}
                        </span>
                </Typography>}
                <Typography variant={'subtitle1'}>
                    Початок о: <span className={classes.textValue}>
                            {formatLocaleDate(stream.startsAt)}
                        </span>
                </Typography>
                <Typography variant={'subtitle1'}>
                    Оновлений в: <span className={classes.textValue}>
                            {formatLocaleDate(stream.updatedAt)}
                        </span>
                </Typography>
                <Typography variant={'subtitle1'}>
                    Створений в: <span className={classes.textValue}>
                            {formatLocaleDate(stream.createdAt)}
                        </span>
                </Typography>
            </Grid>

            {/* Actions */}
            <Grid item xs={2}>
                <div className={classes.actions}>
                    <IconButton className={classes.margin}
                                onClick={onEdit}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton className={classes.margin}
                                onClick={onDelete}>
                        <DeleteIcon/>
                    </IconButton>
                </div>
            </Grid>
        </Grid>

        {viewersFetchStatus === LOADED && <>
            <Accordion className={classes.accordion} expanded={viewersExpanded} onChange={handleViewersExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography className={classes.heading}>Керування доступом глядачів</Typography>
                    <Typography className={classes.secondaryHeading}>
                        Для керування доступом глядачів до стріму розгорніть панель
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ViewersList
                        className={classes.usersTable}
                        viewers={viewers}
                        onHandleCreate={handleUserCreate}
                        onHandleDelete={handleUserDelete}
                    />
                </AccordionDetails>
            </Accordion>
        </>}

        {/* Edit modal */}
        <Modal open={editModalOpen}
               setOpen={setEditModalOpen}
               title={'Редагувати стрім'}
        >
            <EditStreamForm onSubmit={handleEdit} data={stream}/>
        </Modal>

        {/* Delete confirm modal */}
        <ConfirmModal open={deleteModalOpen}
                      setOpen={setDeleteModalOpen}
                      title={'Видалити стрім'}
                      description={'Ви впевнені, що хочете видалити цей стрім?'}
                      onSubmit={handleDelete}
        />
    </>;
}

const mapStateToProps = (state) => ({
    viewers: getViewers(state),
    viewersFetchStatus: getViewersFetchStatus(state)
});
const mapDispatchToProps = {
    fetchViewers, createViewer, deleteViewer
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonDetailTab);
