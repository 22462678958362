import { AUTH_TOKEN_KEY, STANDARD_ERROR_TEXT } from '../constants';
import axios from 'axios';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import * as Sentry from '@sentry/react';

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;

export const setupAxiosInterceptors = (baseUrl, onUnauthenticated) => {
    axios.defaults.baseURL = baseUrl;
    const onRequest = config => {
        const token =
            localStorage.getItem(AUTH_TOKEN_KEY) ||
            sessionStorage.getItem(AUTH_TOKEN_KEY);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    };
    const onResponseSuccess = response => response;
    const onResponseError = err => {
        const status = err.status || (err.response ? err.response.status : 0);
        if (status === 401) {
            onUnauthenticated();
        }
        return Promise.reject(err);
    };
    axios.interceptors.request.use(onRequest);
    axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

const createSelectorCreator = (rootSelector) => (...fn) =>

createSelector(rootSelector, ...fn);

const arg = index => (...args) => (index == null ? args : args[index]);

export { arg, createSelector, createCachedSelector, createSelectorCreator };

export const getErrorMessage = (error) => {
    const handleStandardError = (error) => {
        Sentry.captureException(error);
        return STANDARD_ERROR_TEXT;
    }

    if (!error.response) {
        return handleStandardError(error);
    }

    const status = error.response.status;
    const data = error.response.data;

    if (!data) {
        return handleStandardError(error);
    }

    if (status === 401 || status === 403) {
        return 'Немає доступу до системи за цим номером телефону.';
    }

    const message = data.message || data.detail;
    if (!message) {
        return handleStandardError(error);
    }

    return translateErrorMessage(message);
};

export const translateErrorMessage = (message) => {
    switch (message) {
        case 'chat.message-field-required':
            return 'Текст повідомлення є обов\'зковим.';
        case 'chat.wrong-direction-field':
            return 'Невірний формат поля direction.';
        case 'chat.wrong-timestamp-field':
            return 'Невірний формат поля timestamp.';
        case 'playlist.not-found':
            return 'Стрім не знайдено.';
        case 'admin-auth.incorrect-credentials':
            return 'Немає доступу до системи за цими даними для входу.';
        case 'admin-auth.unauthorized':
            return 'Користувач неавторизований.';
        case 'viewer-auth.incorrect-credentials':
            return 'Немає доступу до системи за цим номером телефону.';
        case 'viewer-auth.access-denied':
            return 'Немає доступу до системи за цим номером телефону.';
        case 'stream.create.name-field-required':
            return 'Поле назви є обов\'зковим.';
        case 'viewers.create.codes-required':
            return 'Поле кодів є обов\'зковим.';
        case 'stream.not-found':
            return 'Стрім не знайдено.';
        case 'chat.not-found':
            return 'Чат не знайдено.';
        case 'chat.not-allowed':
            return 'У Вас немає доступу для надсилання повідомлень до чату.';
        default:
            return STANDARD_ERROR_TEXT;
    }
}
