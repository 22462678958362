import axios from 'axios';
import actionTypes from './actionTypes';
import { push } from 'connected-react-router';
import { AUTH_TOKEN_KEY } from '../../constants';
import { hideLoader, showLoader } from '../ui/loader';
import { createErrorNotification, createSuccessNotification } from '../../Util';
import { enqueueSnackbar } from '../ui/notifications';
import { getErrorMessage } from '../../Util';

export const setupAuthToken = (token) => {
    if (!token) {
        return;
    }
    localStorage.setItem(AUTH_TOKEN_KEY, token);
};

export const loginFailure = error => dispatch => {
    dispatch({
        type: actionTypes.LOGIN_FAILURE,
        error
    });
    dispatch(enqueueSnackbar(createErrorNotification(error)));
    dispatch(hideLoader());
};

export const loginSuccess = token => dispatch => {
    setupAuthToken(token);

    dispatch({
        type: actionTypes.LOGIN_SUCCESS
    });

    dispatch({
        type: actionTypes.UPDATE_ACCESS_TOKEN,
        accessToken: token
    });

    dispatch(getUser());
    dispatch(enqueueSnackbar(createSuccessNotification('Ви успішно увійшли в систему!')));
    dispatch(hideLoader());
};

export const getAccountSuccess = account => dispatch => {
    dispatch({
        type: actionTypes.GET_ACCOUNT_SUCCESS,
        account
    });
    dispatch(hideLoader());
};

export const getAccountFailure = error => dispatch => {
    dispatch({
        type: actionTypes.GET_ACCOUNT_FAILURE,
        error
    });
    dispatch(enqueueSnackbar(createErrorNotification(error)));
    dispatch(hideLoader());
};

export const getUser = () => dispatch => {
    dispatch({
        type: actionTypes.GET_ACCOUNT_REQUEST
    });
    dispatch(showLoader());

    axios
        .get('api/users/me')
        .then(res => {
            dispatch(getAccountSuccess(res.data));
        })
        .catch(error => dispatch(getAccountFailure(getErrorMessage(error))));
};

export const loginUser = (username, password) => dispatch => {
    const authData = { username, password };

    dispatch({
        type: actionTypes.LOGIN_REQUEST
    });
    dispatch(showLoader());

    return axios
        .post('auth/login/user', authData)
        .then(response => {
            if (response.data.accessToken) {
                dispatch(loginSuccess(response.data.accessToken));
            } else {
                dispatch(loginFailure('Не вдалось увійти у систему.'));
                throw new Error();
            }
        })
        .catch(error => {
            dispatch(loginFailure(getErrorMessage(error)));
            throw error;
        });
};

export const loginViewer = (streamKey, code) => dispatch => {
    const authData = { code, streamKey };

    dispatch({
        type: actionTypes.LOGIN_REQUEST
    });
    dispatch(showLoader());

    return axios
        .post('auth/login/viewer', authData)
        .then(response => {
            if (response.data.accessToken) {
                dispatch(loginSuccess(response.data.accessToken));
            } else {
                dispatch(loginFailure('Не вдалось увійти у систему.'));
            }
        })
        .catch(error => dispatch(loginFailure(getErrorMessage(error))));
};

export const clearAuthToken = () => {
    if (localStorage.getItem(AUTH_TOKEN_KEY)) {
        localStorage.removeItem(AUTH_TOKEN_KEY);
    }

    if (sessionStorage.getItem(AUTH_TOKEN_KEY)) {
        sessionStorage.removeItem(AUTH_TOKEN_KEY);
    }
};

export const logout = () => dispatch => {
    clearAuthToken();
    dispatch({
        type: actionTypes.LOGOUT
    });
    dispatch(push('/'));
};
