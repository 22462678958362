import { createSelectorCreator } from '../../../Util';

const createScopedSelector = createSelectorCreator(
    state => state.viewer.chat
);

export const getMessages = createScopedSelector(
    state => state.messages
);

export const getMessagesFetchStatus = createScopedSelector(
    state => state.messagesFetchStatus
);

export const getMessagesFetchError = createScopedSelector(
    state => state.messagesFetchError
);

export const getSendMessageError = createScopedSelector(
    state => state.sendMessageError
);

export const getSendMessageStatus = createScopedSelector(
    state => state.sendMessageStatus
);

export const getCurrentStreamKey = createScopedSelector(
    state => state.streamKey
);

export const isMessagesAbove = createScopedSelector(
    state => state.messagesAbove
);
