const actionTypes = {
    GET_STREAMS_REQUEST: 'streams/GET_STREAMS_REQUEST',
    GET_STREAMS_SUCCESS: 'streams/GET_STREAMS_SUCCESS',
    GET_STREAMS_FAILURE: 'streams/GET_STREAMS_FAILURE',

    CREATE_STREAM_REQUEST: 'streams/CREATE_STREAM_REQUEST',
    CREATE_STREAM_SUCCESS: 'streams/CREATE_STREAM_SUCCESS',
    CREATE_STREAM_FAILURE: 'streams/CREATE_STREAM_FAILURE',

    UPDATE_STREAM_REQUEST: 'streams/UPDATE_STREAM_REQUEST',
    UPDATE_STREAM_SUCCESS: 'streams/UPDATE_STREAM_SUCCESS',
    UPDATE_STREAM_FAILURE: 'streams/UPDATE_STREAM_FAILURE',

    DELETE_STREAM_REQUEST: 'streams/DELETE_STREAM_REQUEST',
    DELETE_STREAM_SUCCESS: 'streams/DELETE_STREAM_SUCCESS',
    DELETE_STREAM_FAILURE: 'streams/DELETE_STREAM_FAILURE',

    GET_STREAM_REQUEST: 'streams/GET_STREAM_REQUEST',
    GET_STREAM_SUCCESS: 'streams/GET_STREAM_SUCCESS',
    GET_STREAM_FAILURE: 'streams/GET_STREAM_FAILURE',
};

export default actionTypes;
