const createNotification = (message, variant = 'default', options = {}) => ({
  message: message,
  options: {
    variant: variant,
    ...options
  }
});

export const createDefaultNotification = (message, options = {}) =>
  createNotification(message, 'default', options);

export const createSuccessNotification = (message, options = {}) =>
  createNotification(message, 'success', options);

export const createInfoNotification = (message, options = {}) =>
  createNotification(message, 'info', options);

export const createErrorNotification = (message, options = {}) =>
  createNotification(message, 'error', options);
