import React, {useState} from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { YOUTUBE } from '../../../../../../constants';

const PlayerFallbackSettingsEdit = (props) => {
    const { onSubmit, data } = props;
    const { fallback } = data;

    const [key, setKey] = useState((fallback && fallback.videoKey) || '');
    // const [player, setPlayer] = useState(data.player || YOUTUBE_PLAYER);
    // const [enabled, setEnabled] = useState((fallback && data.fallbackEnabled) || false);

    const handleSubmit = () => {
        const result = {
            ...data,
            // fallbackEnabled: enabled,
            fallback: {
                ...data.fallback,
                videoKey: key,
                // TODO: choose the player later
                type: YOUTUBE
            }
        };
        if (onSubmit) {
            onSubmit(result);
        }
    }

    const handleFieldChange = (setValue, event) => {
        setValue(event.target.value);
    }

    return (
        <div>
            {/*<FormControl>*/}
            {/*    <InputLabel id="player-label">Програвач</InputLabel>*/}
            {/*    <Select*/}
            {/*        labelId="demo-simple-select-helper-label"*/}
            {/*        id="player-label"*/}
            {/*        value={player}*/}
            {/*        onChange={(event) => handleFieldChange(setPlayer, event)}*/}
            {/*    >*/}
            {/*        <MenuItem value={DEFAULT_PLAYER}>{DEFAULT_PLAYER}</MenuItem>*/}
            {/*        <MenuItem value={YOUTUBE_PLAYER}>{YOUTUBE_PLAYER}</MenuItem>*/}
            {/*    </Select>*/}
            {/*</FormControl>*/}

            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="key"
                label="Ключ"
                name="key"
                autoComplete="key"
                autoFocus
                value={key}
                onChange={(event) =>
                    handleFieldChange(setKey, event)}
            />
            {/*<FormControlLabel*/}
            {/*    control={<Switch checked={enabled}*/}
            {/*                     onChange={(event) => handleCheckboxChange(setEnabled, event)}*/}
            {/*                     name={'enabled'} />}*/}
            {/*    label="Резервний програвач ввімкнений"*/}
            {/*/>*/}
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                Редагувати
            </Button>
        </div>
    );
}

export default PlayerFallbackSettingsEdit;
