import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Sentry from "@sentry/react";

import OnlineStream from './OnlineStream/OnlineStream';
import {getAuthenticatedUser, getUserError, getUserStatus, isAdmin, isAuthenticated} from '../../redux/auth/selectors';
import { getUser, loginViewer } from '../../redux/auth/actions';

import {
    ERROR,
    LOADING,
    UNLOADED,
    STREAM_IS_ONLINE,
    STREAM_LIVE_STATUS,
    STREAM_READY_STATUS,
    STREAM_WILL_START
} from '../../constants';
import JoinStreamAuthForm from '../../Components/Organisms/JoinStreamAuthForm/JoinStreamAuthForm';
import { fetchStreamInfoByViewKey, updateStreamInfo } from '../../redux/viewer/streams/actions';
import { getStreamInfo, getStreamInfoFetchError, getStreamInfoFetchStatus } from '../../redux/viewer/streams/selectors';
import Typography from '@material-ui/core/Typography';
import { formatLocaleDate, getStartsIn } from '../../Util';
import { makeStyles } from '@material-ui/core/styles';

import LiveDot from '../../Components/Atoms/LiveDot/LiveDot';
import classnames from 'classnames';
import Error from '../../Components/Molecules/Error/Error';
import Banner from '../../Components/Molecules/Banner/Banner';

const useStyles = makeStyles((theme) => ({
    name: {
        padding: theme.spacing(1),

    },
    description: {
        marginBottom: theme.spacing(3)
    },
    onlineText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    marginBottom: {
        marginBottom: theme.spacing(1)
    },
    banner: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        }
    },
}));

function Viewer(props) {
    const { streamKey } = useParams();
    const { streamInfo } = props;

    const classes = useStyles();

    useEffect(() => {
        props.fetchStreamInfoByViewKey(streamKey);

        if (props.isAuthenticated) {
            props.getUser();
        }

        const refresh = () => props.updateStreamInfo(streamKey)?.catch(e => Sentry.captureException(e));

        const interval = setInterval(refresh, 1000 * 5);
        refresh();

        return () => {
            clearInterval(interval);
        }
    }, []);

    const handleJoinStream = (data) => {
        props.loginViewer(streamKey, data.code);
    }

    const renderStartsSoon = () => {
        return <div className={classes.banner}>
            <Banner text={'Очікуйте на початок трансляції...'}/>
        </div>
        // return <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
        //     <span>Очікуйте на початок трансляції...</span>
        // </Typography>
    }

    const renderContent = () => {
        if (props.isAuthenticated === false) {
            return <JoinStreamAuthForm onSubmit={handleJoinStream} />;
        }

        if (props.userStatus === UNLOADED || props.userStatus === LOADING) {
            return null;
        } else if (props.userStatus === ERROR) {
            return <Error message={props.userError}/>;
        }

        if (props.isAdmin && (streamInfo.status !== STREAM_LIVE_STATUS || !streamInfo.playlistReady)) {
            return renderStartsSoon();
        }

        if (!streamInfo.allowed || streamInfo.status !== STREAM_LIVE_STATUS || !streamInfo.playlistReady) {
            return renderStartsSoon();
        }

        return <OnlineStream streamKey={streamKey}/>;
    }

    const renderStreamStatus = () => {
        const startsIn = streamInfo.startsAt && getStartsIn(streamInfo.startsAt);

        switch (streamInfo.status) {
            case STREAM_READY_STATUS:
                return startsIn && (<Typography variant="h6" align={'center'}
                                                className={classes.marginBottom}>
                    {STREAM_WILL_START}{' '}{formatLocaleDate(streamInfo.startsAt)}
                </Typography>);
            case STREAM_LIVE_STATUS:
                return <div className={classes.marginBottom}>
                    <div className={classnames([classes.onlineText])}>
                        <Typography variant="h6" align={'center'}>
                            <span>{STREAM_IS_ONLINE}{' '}</span>
                        </Typography>
                        <LiveDot color={'red'}/>
                    </div>
                    <Typography variant="subtitle1" align={'center'}>
                        <span>Кількість глядачів:{' '}{streamInfo.count}</span>
                    </Typography>
                </div>;
            default:
                return null;
        }
    }

    if (props.streamInfoStatus === UNLOADED || props.streamInfoStatus === LOADING) {
        return  null;
    }

    if (props.streamInfoStatus === ERROR) {
        return <Error message={props.streamInfoError} />
    }

    return (
        <div>
            <Typography variant="h4" component="h4" align={'center'}
                        className={classes.name}>
                {streamInfo.name}
            </Typography>
            {streamInfo.description && <Typography variant="subtitle1" align={'center'}
                                                   color={'textSecondary'}
                                                   className={classes.description}>
                {streamInfo.description}
            </Typography>}

            {renderStreamStatus()}
            {renderContent()}
        </div>
    );
}

const mapStateToProps = (state) => ({
    authenticatedUser: getAuthenticatedUser(state),
    isAuthenticated: isAuthenticated(state),
    userStatus: getUserStatus(state),
    userError: getUserError(state),
    streamInfoStatus: getStreamInfoFetchStatus(state),
    streamInfoError: getStreamInfoFetchError(state),
    streamInfo: getStreamInfo(state),
    isAdmin: isAdmin(state)
});
const mapDispatchToProps = { getUser, loginViewer, fetchStreamInfoByViewKey, updateStreamInfo };

export default connect(mapStateToProps, mapDispatchToProps)(Viewer);
