import React, {useState} from 'react';
import {FormControl, Input, InputAdornment} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {Visibility, VisibilityOff} from '@material-ui/icons';

const PasswordColumn = (props) => {
    const { value, id } = props;
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return <FormControl fullWidth>
        <Input
            id={`password${id}`}
            type={showPassword ? 'text' : 'password'}
            value={value}
            disabled
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                    >
                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                </InputAdornment>
            }
        />
    </FormControl>;
}

export default PasswordColumn;
