import React from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Block, Delete } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    popper: {
        padding: theme.spacing(2)
    }
}));

const ChatMessageManager = (props) => {
    const classes = useStyles();

    const handleMessageDelete = () => {
        if (props.onMessageDelete) {
            props.onMessageDelete();
        }
    }

    const handleAuthorBan = () => {
        if (props.onAuthorBan) {
            props.onAuthorBan();
        }
    }

    return (
        <div className={classes.popper}>
            <div className={classes.actions}>
                <IconButton onClick={handleMessageDelete}>
                    <Delete/>
                </IconButton>
                {props.banAllowed && <IconButton onClick={handleAuthorBan}>
                    <Block/>
                </IconButton>}
            </div>
        </div>
    );
}

export default ChatMessageManager;
