import Moment from 'moment';
import 'moment/locale/uk';

const ISO_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS';

/**
 * Format a date to the DD-MM-YYYY hh:mm:ss a format
 *
 * @param date
 * @returns {string}
 */
export function formatDate(date) {
    return Moment(date)
        .local()
        .format('DD-MM-YYYY hh:mm:ss a');
}

/**
 * Format a date to the DD-MM-YYYY hh:mm:ss a format for UK locale
 *
 * @param date
 * @returns {string}
 */
export function formatLocaleDate(date) {
    const localLocale = Moment(date);

    // TODO: find a way to customize this date format
    localLocale.locale('uk');
    return localLocale.format('LLL')
        // replace the year word
        .replace('р.,', 'року о');
}

/**
 *
 * @param date
 * @returns {string}
 */
export function getISODate(date) {
    const localLocale = Moment(date);

    localLocale.locale('uk');
    return localLocale.format(ISO_FORMAT);
    // return Moment(date).format(ISO_FORMAT);
}

export function getUTCISODate(date) {
    return Moment(date).utc().format(ISO_FORMAT);
}

/**
 * Get the starts in value
 *
 * @param date
 * @returns {string|null}
 */
export function getStartsIn(date) {
    if (Moment().isAfter(date)) {
        return null;
    }

    return Moment().to(date);
}
