import { combineReducers } from 'redux';

import streams from './streams/reducer';
import viewers from './viewers/reducer';
import chat from './chat/reducer';

export default combineReducers({
    streams,
    viewers,
    chat
});
