import { createSelectorCreator } from '../../../Util';

const createScopedSelector = createSelectorCreator(
    state => state.admin.streams
);

export const getStreams = createScopedSelector(
    state => state.streams
);

export const getStreamsFetchStatus = createScopedSelector(
    state => state.streamsFetchStatus
);

export const getStreamsFetchError = createScopedSelector(
    state => state.streamsFetchError
);

export const getStream = createScopedSelector(
    state => state.stream
);

export const getStreamFetchStatus = createScopedSelector(
    state => state.streamFetchStatus
);

export const getStreamFetchError = createScopedSelector(
    state => state.streamFetchError
);
