import React from 'react';

import { Grid, makeStyles, Typography } from '@material-ui/core';

import PlayerFallbackSettings from './PlayerFallbackSettings/PlayerFallbackSettings';
import RetranslationSettings from './RetranslationSettings/RetranslationSettings';
import TranscodingSettings from './TranscodingSettings/TranscodingSettings';

const useStyles = makeStyles((theme) => ({
    title: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    }
}));

export default function StreamSettingsDetailTab(props) {
    const {
        stream,
        updateStream
    } = props;

    const classes = useStyles();

    const handleUpdate = (data) => {
        updateStream(data);
    }

    return <Grid container spacing={3}>

        {/* Stream info */}
        <Grid item xs={6}>
            <Typography variant={'h5'} color={'textSecondary'} className={classes.title}>
                Налаштування ретрансляції
            </Typography>
            <RetranslationSettings stream={stream} updateStream={handleUpdate}/>
            <TranscodingSettings stream={stream}/>
        </Grid>
        <Grid item xs={6}>
            <Typography variant={'h5'} color={'textSecondary'} className={classes.title}>
                Налаштування резервного програвача
            </Typography>
            <PlayerFallbackSettings stream={stream} updateStream={handleUpdate}/>
        </Grid>
    </Grid>;
}
