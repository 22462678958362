import React, {useEffect} from 'react';

import {IconButton, Typography} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Modal from '../../Molecules/Modal/Modal';
import List from '@material-ui/core/List';
import {connect} from "react-redux";
import { isAdmin } from '../../../redux/auth/selectors';
import { getBannedViewers } from '../../../redux/admin/chat/selectors';
import { banUser, fetchBannedViewers } from '../../../redux/admin/chat/actions';

const ChatUnbanModal = (props) => {
    const { openModal, setOpenModal, bannedViewers, streamKey } = props;

    useEffect(() => {
        if (openModal === true){
            props.fetchBannedViewers(streamKey);
        }
    }, [openModal]);

    const handleUnban = (code) => {
        props.banUser(streamKey, {
            code,
            unban: true
        });
    }

    const renderSinglePhone = (code) => {
        return (<ListItem>
            <ListItemText primary={code} />
            <IconButton
                onClick={() => handleUnban(code)}
            >
                <Delete/>
            </IconButton>
        </ListItem>);
    }

    return <Modal title={'Список заблокованих користувачів'}
                  open={openModal}
                  setOpen={setOpenModal}
    >
        <List>
            {bannedViewers && bannedViewers.length > 0 ? bannedViewers.map(banned => renderSinglePhone(banned))
            : <Typography variant={'subtitle1'}>
                    Немає заблокованих користувачів
                </Typography>}
        </List>
    </Modal>;
}

const mapStateToProps = (state) => ({
    isAdmin: isAdmin(state),
    bannedViewers: getBannedViewers(state)
});

const mapDispatchToProps = {
    banUser,
    fetchBannedViewers
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatUnbanModal);
