import React from 'react';

import './Users.scss';
import UsersList from './UsersList/UsersList';

import { users } from './data';

function Users() {
    const handleCreate = (data) => {
        // TODO: call API to create a new stream
        console.log('create', data);
    }

    const handleEdit = (data) => {
        // TODO: call API to edit stream
        console.log('edit', data);
    }

    const handleDelete = () => {
        // TODO: call API to delete the stream
        console.log('delete row');
    }

    return (
        <UsersList
            users={users}
            onHandleCreate={handleCreate}
            onHandleEdit={handleEdit}
            onHandleDelete={handleDelete}
        />
    );
}

export default Users;
