import './DefaultWelcome.scss';
import planet from '../../../assets/images/planet.png';
import { APP_NAME } from '../../../constants';

function DefaultWelcome() {
    return (
        <div className="DefaultWelcome">
            <header className="DefaultWelcome-header">
                <img src={planet} className="image" alt="logo" />
                <p>
                    Welcome to the {APP_NAME}!
                </p>
            </header>
        </div>
    );
}

export default DefaultWelcome;
