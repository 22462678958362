import actionTypes from './actionTypes';
import { ERROR, LOADED, LOADING, UNLOADED } from '../../../constants';

const initialState = {
    viewersFetchStatus: UNLOADED,
    viewersFetchError: null,
    viewers: [],

    createViewerStatus: UNLOADED,
    createViewerError: null,

    deleteViewerStatus: UNLOADED,
    deleteViewerError: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_VIEWERS_REQUEST:
            return {
                ...state,
                viewersFetchStatus: LOADING
            };
        case actionTypes.GET_VIEWERS_FAILURE:
            return {
                ...state,
                viewersFetchStatus: ERROR,
                viewersFetchError: action.error
            };

        case actionTypes.GET_VIEWERS_SUCCESS:
            return {
                ...state,
                viewersFetchStatus: LOADED,
                viewers: action.viewers
            };

        case actionTypes.CREATE_VIEWER_REQUEST:
            return {
                ...state,
                createViewerStatus: LOADING
            };
        case actionTypes.CREATE_VIEWER_FAILURE:
            return {
                ...state,
                createViewerStatus: ERROR
            };

        case actionTypes.CREATE_VIEWER_SUCCESS:
            return {
                ...state,
                createViewerStatus: LOADED
            };

        case actionTypes.DELETE_VIEWER_REQUEST:
            return {
                ...state,
                deleteViewerStatus: LOADING
            };
        case actionTypes.DELETE_VIEWER_FAILURE:
            return {
                ...state,
                deleteViewerStatus: ERROR
            };

        case actionTypes.DELETE_VIEWER_SUCCESS:
            return {
                ...state,
                deleteViewerStatus: LOADED
            };

        default:
            return state;
    }
};
