import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './redux/reducer';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';

import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const defaultMiddlewares = [
    routerMiddleware(history),
    thunkMiddleware
];

const composedEnhancer = composeWithDevTools(
    // Add whatever middleware you actually want to use here
    applyMiddleware(...defaultMiddlewares),
    // other store enhancers if any
)

const store = createStore(
    rootReducer(history),
    composedEnhancer
);
export default store;
