import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
import {useEffect} from 'react';
import {connect} from 'react-redux';

import Admin from '../Pages/Admin/Admin';
import PrivateRoute from '../Components/Molecules/PrivateRoute/PrivateRoute';

import { ROLE_ADMIN } from '../constants';

import { getAuthenticatedUser, getUserError, getUserStatus, isAuthenticated}  from '../redux/auth/selectors';
import { getUser } from '../redux/auth/actions';
import { ERROR, LOADING } from '../constants';
import Login from '../Pages/Login/Login';
import Error from '../Components/Molecules/Error/Error';


function Application(props) {
    const { isAuthenticated, userStatus } = props;
    useEffect(() => {
        if (isAuthenticated) {
            props.getUser();
        }
    }, []);

    const match = useRouteMatch();

    if (userStatus === LOADING) {
        return null;
    } else if (userStatus === ERROR) {
        return <Error message={props.userError} />;
    }

    return (
        <Switch>
            <Route path={`${match.url}/login`}>
                <Login/>
            </Route>
            <PrivateRoute
                path={`${match.url}`}
                component={Admin}
                roles={[ROLE_ADMIN]}
            />
            <Redirect to={isAuthenticated ? match.url : `${match.url}/login`} />
        </Switch>
    );
}

const mapStateToProps = (state) => ({
    authenticatedUser: getAuthenticatedUser(state),
    isAuthenticated: isAuthenticated(state),
    userStatus: getUserStatus(state),
    userError: getUserError(state)
});
const mapDispatchToProps = { getUser };

export default connect(mapStateToProps, mapDispatchToProps)(Application);
