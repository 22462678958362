import React from 'react';

import Typography from '@material-ui/core/Typography';
import { Mail, Phone } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    link: {
        color: 'inherit',
        textDecoration: 'none',
        display: 'flex',
        paddingLeft: '10px',
        paddingRight: '10px',
    }
}));

export const EMAIL_TYPE = 'email';
export const PHONE_TYPE = 'phone';

const ContactLink = (props) => {
    const { value, type } = props;
    const classes = useStyles();

    const getLinkPrefix = () => {
        switch (type) {
            case EMAIL_TYPE:
                return 'mailto';
            case PHONE_TYPE:
                return 'tel';
            default:
                return '';
        }
    }

    const getLinkIcon = () => {
        switch (type) {
            case EMAIL_TYPE:
                return <Mail style={{marginRight: '5px'}}/>;
            case PHONE_TYPE:
                return <Phone style={{marginRight: '5px'}}/>;
            default:
                return null;
        }
    }

    return <a className={classes.link} href={`${getLinkPrefix()}:${value}`}>
        {getLinkIcon()}
        <Typography variant={'body1'}>
            <span>{value}</span>
        </Typography>
    </a>;
}

export default ContactLink;
