import {createSelectorCreator} from '../../Util';

export const ENQUEUE_SNACKBAR = 'UI/ENQUEUE_SNACKBAR';
export const REMOVE_SNACKBAR = 'UI/REMOVE_SNACKBAR';

const initialState = {
  notifications: []
};

const createScopedSelector = createSelectorCreator(state => state.ui.notifications);

export const getNotifications = createScopedSelector(
    (state) => state.notifications
);

export const enqueueSnackbar = notification => ({
  type: ENQUEUE_SNACKBAR,
  notification: {
    key: new Date().getTime() + Math.random(),
    ...notification
  }
});

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key
});

export default (state = initialState, action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification
          }
        ]
      };
    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key
        )
      };
    default:
      return state;
  }
};
