import React, { useState } from 'react';

import DataTable from '../../DataTable/DataTable';
import Modal from '../../../Molecules/Modal/Modal';
import CreateStreamForm from '../CreateStreamForm/CreateStreamForm';
import ConfirmModal from '../../../Molecules/ConfirmModal/ConfirmModal';
import EditStreamForm from '../EditStreamForm/EditStreamForm';
import { formatLocaleDate } from '../../../../Util';

import RefreshIcon from '@material-ui/icons/Refresh';

const columns = [
    {title: 'Id', field: 'id'},
    {title: 'Назва', field: 'name'},
    // {title: 'Key', field: 'key'},
    {title: 'Статус', field: 'status'},
    {title: 'Ввімкнений', field: 'enabled', type: 'boolean'},
    {title: 'Дата початку', field: 'startsAt',
        render: rowData => formatLocaleDate(rowData.startsAt)},
    // {title: 'Updated at', field: 'updatedAt', render: rowData => formatDate(rowData.updatedAt)},
    // {title: 'Created at', field: 'createdAt', render: rowData => formatDate(rowData.createdAt)},
    // {title: 'Viewers', field: 'viewers'},
];

function StreamsList(props) {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [deleteRow, setDeleteRow] = useState(null);
    const [editRow, setEditRow] = useState(null);

    const {
        streams,
        onHandleCreate,
        onHandleEdit,
        onHandleDelete,
        onHandleDetail,
        onHandleRefresh
    } = props;

    const onAdd = () => {
        setCreateModalOpen(true);
    }

    const onDetail = (row) => {
        onHandleDetail(row);
    }

    const onDelete = (row) => {
        setDeleteRow(row);
        setDeleteModalOpen(true);
    }

    const onEdit = (row) => {
        setEditRow(row);
        setEditModalOpen(true);
    }

    const handleCreate = (data) => {
        onHandleCreate(data);
        setCreateModalOpen(false);
    }

    const handleEdit = (data) => {
        onHandleEdit(data);
        setEditModalOpen(false);
    }

    const handleDelete = () => {
        onHandleDelete(deleteRow);
        setDeleteModalOpen(false);
    }

    const handleRefresh = () => {
        onHandleRefresh();
    }

    return (<>
            <DataTable
                tableType='Стрім'
                title={'Стріми'}
                columns={columns}
                data={streams}
                onNew={onAdd}
                onDetail={onDetail}
                onDelete={onDelete}
                onEdit={onEdit}
                extraActions={[
                    {
                        icon: () =>  <RefreshIcon/>,
                        tooltip: 'Оновити',
                        onClick: handleRefresh
                    }
                ]}
            />

            {/* Create modal */}
            <Modal open={createModalOpen}
                   setOpen={setCreateModalOpen}
                   title={'Додати стрім'}
            >
                <CreateStreamForm onSubmit={handleCreate}/>
            </Modal>

            {/* Edit modal */}
            <Modal open={editModalOpen}
                   setOpen={setEditModalOpen}
                   title={'Редагувати стрім'}
            >
                <EditStreamForm onSubmit={handleEdit} data={editRow}/>
            </Modal>

            {/* Delete confirm modal */}
            <ConfirmModal open={deleteModalOpen}
                          setOpen={setDeleteModalOpen}
                          title={'Видалити стрім'}
                          description={'Ви впевнені, що хочете видалити цей стрім?'}
                          onSubmit={handleDelete}
            />
        </>
    );
}

export default StreamsList;
