import { createSelectorCreator } from '../../Util';

export const SHOW_LOADER = 'UI/SHOW_LOADER';
export const HIDE_LOADER = 'UI/HIDE_LOADER';

const initialState = {
  loading: false
};

const createScopedSelector = createSelectorCreator(state => state.ui.loader);

export const isLoading = createScopedSelector(
  (state) => state.loading
);

export const showLoader = () => ({
  type: SHOW_LOADER
});

export const hideLoader = () => ({
  type: HIDE_LOADER
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        ...state,
        loading: true
      };
    case HIDE_LOADER:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
