import React from 'react';

import './DataTable.scss';
import MaterialTable, { MTableBodyRow } from '@material-table/core';
import { Add, DragIndicator, ArrowRightAlt } from '@material-ui/icons';
import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from 'react-sortable-hoc';
import { useHistory } from 'react-router-dom';
import { Button, Icon, IconButton, Tooltip } from '@material-ui/core';

import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ZoomIn from '@material-ui/icons/ZoomIn';
import Delete from '@material-ui/icons/Delete';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export const DataTable = SortableContainer((props) => {
    const DragHandle = SortableHandle(() => <DragIndicator/>);
    const history = useHistory();

    const tableType = props.tableType;

    const getActions = () => {
        const extraActions = props.extraActions ? props.extraActions.map(action => ({
            ...action,
            isFreeAction: true,
        })) : [];

        let actions = [
            props.rowDraggable && {
                icon: () => <DragHandle/>,
                tooltip: 'Drag&Drop',
                onClick: () => null
            },
            props.onDelete && {
                icon: <Delete/>,
                tooltip: 'Delete',
                onClick: (event, rowData) => props.onDelete(rowData)
            },
            props.onDetail && {
                icon: <ZoomIn/>,
                tooltip: 'Detail',
                onClick: (event, rowData) => props.onDetail(rowData)
            },
            props.onEdit && !props.editable && {
                icon: <Edit/>,
                tooltip: 'Edit',
                onClick: (event, rowData) => props.onEdit(rowData)
            },
            props.onNew && !props.editable && {
                icon: () => <Add/>,
                tooltip: 'Add new',
                isFreeAction: true,
                onClick: () => props.onNew()
            },
            props.back && {
                icon: () => <ArrowRightAlt/>,
                tooltip: 'Back',
                isFreeAction: true,
                onClick: () => history.goBack()
            }];

        if (extraActions) {
            actions = [...actions, ...extraActions];
        }
        return actions;
    }

    return (
        <div style={{ maxWidth: '100%' }}>
            <MaterialTable
                icons={tableIcons}
                columns={props.columns}
                data={props.data}
                title={props.title || ''}
                {
                    ...(props.onRowClick ? {
                        onRowClick: props.onRowClick
                    } : {})
                }
                actions={getActions()}
                options={{
                    paging: props.paging,
                    selection: !!props.onSelection,
                    search: false,
                    actionsColumnIndex: -1
                }}
                components={{
                    Row: props.rowDraggable
                        ? SortableElement(props => <MTableBodyRow {...props} />)
                        : props => <MTableBodyRow {...props} />,
                    Action: props => {
                        if (props.action.icon === 'add') {
                            return (
                                <Button
                                    variant={'outlined'}
                                    color={'secondary'}
                                    endIcon={<Add/>}
                                    onClick={(event) => props.action.onClick()}
                                >
                                    {`${tableType || ''} create`}
                                </Button>
                            );
                        }
                        return <TableAction {...props}/>;
                    }
                }}
                {...(props.onSelection ? {
                    onSelectionChange: props.onSelection
                } : {})}
                {...(props.renderDetailPanel ? {
                    detailPanel: props.renderDetailPanel
                } : {})}
            />
        </div>
    );
});

const TableAction = (props) => {
    let action = props.action;

    if (typeof action === 'function') {
        action = action(props.data);
        if (!action) {
            return null;
        }
    }

    if (action.action) {
        action = action.action(props.data);
        if (!action) {
            return null;
        }
    }

    if (action.hidden) {
        return null;
    }

    const disabled = action.disabled || props.disabled;

    const handleOnClick = (event) => {
        if (action.onClick) {
            action.onClick(event, props.data);
            event.stopPropagation();
        }
    };

    const icon = typeof action.icon === 'string' ? (
        <Icon {...action.iconProps}>{action.icon}</Icon>
    ) : typeof action.icon === 'function' ? (
        action.icon({ ...action.iconProps, disabled: disabled })
    ) : action.icon;

    const button = (
        <IconButton
            size={props.size}
            color="inherit"
            disabled={disabled}
            onClick={handleOnClick}
        >
            {icon}
        </IconButton>
    );

    if (action.tooltip) {
        return disabled ? (
            <Tooltip title={action.tooltip}>
                <span>{button}</span>
            </Tooltip>
        ) : (
            <Tooltip title={action.tooltip}>{button}</Tooltip>
        );
    } else {
        return button;
    }
};

export default DataTable;
