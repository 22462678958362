import axios from 'axios';
import actionTypes from './actionTypes';
import { hideLoader, showLoader } from '../../ui/loader';
import { enqueueSnackbar } from '../../ui/notifications';
import { createErrorNotification, createSuccessNotification, getErrorMessage } from '../../../Util';

const API_URL_PREFIX = 'api/streams';
const API_URL_SUFFIX = 'viewers';

export const fetchViewersSuccess = (viewers) => dispatch => {
    dispatch({
        type: actionTypes.GET_VIEWERS_SUCCESS,
        viewers
    });
    dispatch(hideLoader());
}

export const fetchViewersFailure = error => dispatch => {
    dispatch({
        type: actionTypes.GET_VIEWERS_FAILURE,
        error
    });
    dispatch(enqueueSnackbar(createErrorNotification(error)));
}

export const createViewerSuccess = (streamId) => dispatch => {
    dispatch({
        type: actionTypes.CREATE_VIEWER_SUCCESS
    });
    // Fetch streams after creating new one
    dispatch(fetchViewers(streamId));
    dispatch(enqueueSnackbar(createSuccessNotification('Successfully created new viewer!')))
}

export const createViewerFailure = error => dispatch => {
    dispatch({
        type: actionTypes.CREATE_VIEWER_FAILURE,
        error
    });
    dispatch(enqueueSnackbar(createErrorNotification(error)));
    dispatch(hideLoader());
}

export const deleteViewerSuccess = (streamId) => dispatch => {
    dispatch({
        type: actionTypes.DELETE_VIEWER_SUCCESS
    });
    // Fetch streams after creating new one
    dispatch(fetchViewers(streamId));
    dispatch(enqueueSnackbar(createSuccessNotification('Глядач успішно видалений!')));
}

export const deleteViewerFailure = error => dispatch => {
    dispatch({
        type: actionTypes.DELETE_VIEWER_FAILURE,
        error
    });
    dispatch(enqueueSnackbar(createErrorNotification(error)));
    dispatch(hideLoader());

}

export const fetchViewers = (streamId) => dispatch => {
    dispatch({
        type: actionTypes.GET_VIEWERS_REQUEST
    });
    dispatch(showLoader());

    return axios
        .get(`/${API_URL_PREFIX}/${streamId}/${API_URL_SUFFIX}`)
        .then(res => dispatch(fetchViewersSuccess(res.data)))
        .catch(error => dispatch(fetchViewersFailure(getErrorMessage(error))));
};

export const createViewer = (streamId, payload) => dispatch => {
    dispatch({
        type: actionTypes.CREATE_VIEWER_REQUEST
    });
    dispatch(showLoader());

    axios
        .post(`/${API_URL_PREFIX}/${streamId}/${API_URL_SUFFIX}`, payload)
        .then(response => dispatch(createViewerSuccess(streamId)))
        .catch(error => dispatch(createViewerFailure(getErrorMessage(error))));
};

export const deleteViewer = (streamId, code) => dispatch => {
    dispatch({
        type: actionTypes.DELETE_VIEWER_REQUEST
    });
    dispatch(showLoader());

    axios
        .delete(`/${API_URL_PREFIX}/${streamId}/${API_URL_SUFFIX}/${code}`)
        .then(response => dispatch(deleteViewerSuccess(streamId)))
        .catch(error => dispatch(deleteViewerFailure(getErrorMessage(error))));
};
