import { createSelectorCreator } from '../../../Util';

const createScopedSelector = createSelectorCreator(
    state => state.viewer.streams
);

export const getStream = createScopedSelector(
    state => state.stream
);

export const getStreamFetchStatus = createScopedSelector(
    state => state.streamFetchStatus
);

export const getStreamFetchError = createScopedSelector(
    state => state.streamFetchError
);

export const getStreamInfo = createScopedSelector(
    state => state.streamInfo
);

export const getStreamInfoFetchStatus = createScopedSelector(
    state => state.streamInfoFetchStatus
);

export const getStreamInfoFetchError = createScopedSelector(
    state => state.streamInfoFetchError
);
