import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function Modal(props) {
    const { open, setOpen, title, contextText, children, onSubmit } = props;

    const handleClose = () => {
        setOpen(false);
    };

    // const handleSubmit = () => {
    //     if (onSubmit) {
    //         onSubmit();
    //     }
    // }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    {contextText && <DialogContentText>
                        {contextText}
                    </DialogContentText>}
                    {children}
                </DialogContent>
                <DialogActions>
                    {/*<Button onClick={handleClose} color="primary">*/}
                    {/*    Cancel*/}
                    {/*</Button>*/}
                    {/*<Button onClick={handleSubmit} color="primary">*/}
                    {/*    Submit*/}
                    {/*</Button>*/}
                </DialogActions>
            </Dialog>
        </div>
    );
}
