import { ROLE_ADMIN, ROLE_USER } from '../constants';

export const identifyUserRoutePrefix = (roles) => {
    if (!roles) {
        return '/home';
    }

    if (roles.includes(ROLE_ADMIN)) {
        return '/admin';
    }
    if (roles.includes(ROLE_USER)) {
        return '/app';
    }
    return '/home';
}

export const hasAccessByRoles = (route, roles) => {
    switch (route) {
        case '/admin':
            return roles.includes(ROLE_ADMIN);
        case '/app':
            return roles.length > 0;
        default:
            return false;
    }
}

export const isAdmin = (user) => {
    return user && user.roles && user.roles.includes(ROLE_ADMIN);
}
