import { createSelectorCreator } from '../../../Util';

const createScopedSelector = createSelectorCreator(
    state => state.admin.viewers
);

export const getViewers = createScopedSelector(
    state => state.viewers
);

export const getViewersFetchStatus = createScopedSelector(
    state => state.viewersFetchStatus
);

export const getViewersFetchError = createScopedSelector(
    state => state.viewersFetchError
);
