export const users = [
    {
        id: 1,
        name: 'Alexandra',
        login: 'alexandra',
        password: 'unique1',
        active: true,
        updatedAt: new Date(),
        createdAt: new Date()
    },
    {
        id: 2,
        name: 'Danil',
        login: 'danil',
        password: 'unique2',
        active: true,
        updatedAt: new Date(),
        createdAt: new Date()
    },
    {
        id: 3,
        name: 'Ihor',
        login: 'ihor',
        password: 'unique3',
        active: false,
        updatedAt: new Date(),
        createdAt: new Date()
    },
    {
        id: 4,
        name: 'Vyacheslav',
        login: 'vyacheslav',
        password: 'unique4',
        active: true,
        updatedAt: new Date(),
        createdAt: new Date()
    },
    {
        id: 5,
        name: 'Dmitriy',
        login: 'dmitriy',
        password: 'unique5',
        active: false,
        updatedAt: new Date(),
        createdAt: new Date()
    },
];
