import './Home.scss';
import { Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt, faStar } from '@fortawesome/free-solid-svg-icons'
import Banner from '../../Components/Molecules/Banner/Banner';

const useStyles = makeStyles((theme) => ({
    banner: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        }
    },
    link: {
        color: theme.palette.grey[600]
    }
}));

function Home() {
    const classes = useStyles();

    return (<>
            <Typography align={'center'} variant={'h4'}>
                Шановні клієнти! Вітаємо Вас на мультимедійній платформі дистанційної школи «Оптіма»!
            </Typography>
            <br/>
            <Typography align={'center'} variant={'h5'}>
                Бажаємо вам цікавого та комфортного навчання! {' '}
                <FontAwesomeIcon color={'#ffc401'} icon={faStar}/>
            </Typography>

            <div className={classes.banner}>
                <Banner text={'Вітаємо на навчальному сайті\n' +
                'загальноосвітньої дистанційної школи\n' +
                'Центр освіти "Оптіма"'}/>
            </div>

            {/*<DefaultWelcome/>*/}
            <Typography align={'center'} variant={'body1'}>
                Якщо Ви цікавитесь дистанційним навчанням, пропонуємо завітати на сайт школи та дізнатись більше про
                сучасну дистанційну освіту: {' '}
                <a className={classes.link} target={'_blank'} href={'https://optima.school'}>
                    <span>optima.school</span>{' '}
                    <FontAwesomeIcon icon={faExternalLinkAlt}/>
                </a>
            </Typography>
            <br/>
            <Typography align={'center'} variant={'body1'}>
                Центр освіти «Оптіма» пропонує повний асортимент послуг для успіху дитини та комфорту родини: від
                стандартних освітніх пакетів до тьюторингу та цікавих розвивальних ігор і курсів.
            </Typography>
            <br/>
            <Typography align={'center'} variant={'body1'}>
                Нам довіряють більш ніж 7 000 родин з усього світу! Приєднуйтесь!
            </Typography>
        </>
    );
}

export default Home;
