import {
    Switch,
    Route,
    useRouteMatch,
    Redirect
} from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import Streams from './Streams/Streams';
import Users from './Users/Users';
import StreamDetail from './StreamDetail/StreamDetail';

function Admin() {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.url}/dashboard`}>
                <Dashboard/>
            </Route>
            <Route path={`${match.url}/streams/:streamId`}>
                <StreamDetail/>
            </Route>
            <Route path={`${match.url}/streams`}>
                <Streams/>
            </Route>
            <Route path={`${match.url}/users`}>
                <Users/>
            </Route>
            <Redirect key='admin_dashboard_redirect'
                      to={`${match.url}/dashboard`}
            />
        </Switch>
    );
}

export default Admin;
