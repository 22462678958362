import { makeStyles } from '@material-ui/core/styles';

import { IconButton } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faViber, faTelegramPlane, faYoutube, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { FACEBOOK_LINK, TELEGRAM_LINK, VIBER_LINK, YOUTUBE_LINK } from '../../../constants';

const useStyles = makeStyles((theme) => ({
    socialButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(3)
    },
    icon: {
        padding: '5px'
    }
}));

const SocialButtons = (props) => {
    const classes = useStyles();
    const { color } = props;

    return <div className={classes.socialButtons}>
        <a href={YOUTUBE_LINK} target={'_blank'}>
            <IconButton className={classes.icon} aria-label="youtube">
                <FontAwesomeIcon color={color} icon={faYoutube}/>
            </IconButton>
        </a>

        <a href={FACEBOOK_LINK} target={'_blank'}>
            <IconButton className={classes.icon} aria-label="facebook">
                <FontAwesomeIcon color={color} icon={faFacebook}/>
            </IconButton>
        </a>

        <a href={VIBER_LINK} target={'_blank'}>
            <IconButton className={classes.icon} aria-label="viber">
                <FontAwesomeIcon color={color} icon={faViber}/>
            </IconButton>
        </a>

        <a href={TELEGRAM_LINK} target={'_blank'}>
            <IconButton className={classes.icon} aria-label="telegram">
                <FontAwesomeIcon color={color} icon={faTelegramPlane}/>
            </IconButton>
        </a>
    </div>;
}

export default SocialButtons;
