const actionTypes = {
    SET_MESSAGES_ABOVE: 'viewer/chat/SET_MESSAGES_ABOVE',
    SET_CURRENT_STREAM_KEY: 'viewer/chat/SET_CURRENT_STREAM_KEY',
    DELETE_MESSAGES: 'viewer/chat/DELETE_MESSAGES',
    ADD_MESSAGES: 'viewer/chat/ADD_MESSAGES',

    GET_MESSAGES_REQUEST: 'viewer/chat/GET_MESSAGES_REQUEST',
    GET_MESSAGES_SUCCESS: 'viewer/chat/GET_MESSAGES_SUCCESS',
    GET_MESSAGES_FAILURE: 'viewer/chat/GET_MESSAGES_FAILURE',

    SEND_MESSAGE_REQUEST: 'viewer/chat/SEND_MESSAGE_REQUEST',
    SEND_MESSAGE_SUCCESS: 'viewer/chat/SEND_MESSAGE_SUCCESS',
    SEND_MESSAGE_FAILURE: 'viewer/chat/SEND_MESSAGE_FAILURE',
};

export default actionTypes;
