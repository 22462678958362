import axios from 'axios';
import actionTypes from './actionTypes';
import { hideLoader, showLoader } from '../../ui/loader';
import { enqueueSnackbar } from '../../ui/notifications';
import { createErrorNotification, getErrorMessage } from '../../../Util';
import {isAuthenticated} from '../../auth/selectors';
import * as Sentry from '@sentry/react';

const API_URL = 'api/streams';

export const fetchStreamSuccess = stream => dispatch => {
    dispatch({
        type: actionTypes.GET_STREAM_SUCCESS,
        stream
    });
    dispatch(hideLoader());
}

export const fetchStreamFailure = error => dispatch => {
    dispatch({
        type: actionTypes.GET_STREAM_FAILURE,
        error
    });
    dispatch(enqueueSnackbar(createErrorNotification(error)));
    dispatch(hideLoader());
}

export const fetchStreamInfoSuccess = stream => dispatch => {
    dispatch({
        type: actionTypes.GET_STREAM_INFO_SUCCESS,
        stream
    });
    dispatch(hideLoader());
}

export const fetchStreamInfoFailure = error => dispatch => {
    dispatch({
        type: actionTypes.GET_STREAM_INFO_FAILURE,
        error
    });
    dispatch(enqueueSnackbar(createErrorNotification(error)));
    dispatch(hideLoader());
}

export const fetchStreamByViewKey = (viewKey) => dispatch => {
    dispatch({
        type: actionTypes.GET_STREAM_REQUEST
    });
    dispatch(showLoader());

    axios
        .get(`/${API_URL}/view/${viewKey}`)
        .then(res => dispatch(fetchStreamSuccess(res.data)))
        .catch(error => dispatch(fetchStreamFailure(getErrorMessage(error))));
};

export const fetchStreamInfoByViewKey = (streamKey) => dispatch => {
    dispatch({
        type: actionTypes.GET_STREAM_INFO_REQUEST
    });
    dispatch(showLoader());

    axios
        .get(`${API_URL}/${streamKey}/info`)
        .then(res => dispatch(fetchStreamInfoSuccess(res.data)))
        .catch(error => dispatch(fetchStreamInfoFailure(getErrorMessage(error))));
};

export const updateStreamInfo = (streamKey) => (dispatch, getState) => {
    if (!isAuthenticated(getState())) {
        return;
    }

    return axios
        .get(`${API_URL}/view/${streamKey}`)
        .then(res => dispatch({
            type: actionTypes.UPDATE_STREAM_INFO,
            count: res.data.count,
            status: res.data.status,
            playlistReady: res.data.playlistReady,
            playback: res.data.playback,
            allowed: res.data.allowed
        }))
        .catch(error => {
            if (error.response.status === 404) {
                dispatch(fetchStreamInfoByViewKey(streamKey));
                throw error;
            }
            Sentry.captureException(error);
        });
}
