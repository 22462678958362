import React, {useState} from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const LoginForm = (props) => {
    const { classes, onSubmit } = props;
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        const data = { login, password };
        e.preventDefault();
        if (onSubmit) {
            onSubmit(data);
        }
    }

    const handleFieldChange = (setValue, event) => {
        setValue(event.target.value);
    }

    return (
        <div className={classes.form}>
          <form onSubmit={handleSubmit}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="login"
                label="Логін"
                name="login"
                autoComplete="login"
                autoFocus
                value={login}
                onChange={(event) =>
                    handleFieldChange(setLogin, event)}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Пароль"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(event) =>
                    handleFieldChange(setPassword, event)}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                // onClick={handleSubmit}
            >
                Увійти
            </Button>
          </form>
        </div>
    );
}

export default LoginForm;
