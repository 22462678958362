import React, { useState } from 'react';

import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import PhoneInput from '../../Atoms/PhoneInput/PhoneInput';
import Typography from "@material-ui/core/Typography";
import {faExternalLinkAlt, faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
    },
    form: {
        // width: '100%', // Fix IE 11 issue.
        // marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    link: {
        color: theme.palette.grey[600],
        marginLeft: theme.spacing(1)
    }
}));

const JoinStreamAuthForm = (props) => {
    const { onSubmit } = props;
    const [phone, setPhone] = useState('');
    const [phoneValid, setPhoneValid] = useState(true);

    const classes = useStyles();

    const handleSubmit = () => {
        // TODO: when API is updated, change code to phone
        const data = { code: phone };

        // Validate phone field
        if (!phoneValid) {
            return;
        }

        if (onSubmit) {
            onSubmit(data);
        }
    }

    const handlePhoneChange = (event, valid) => {
        setPhone(event.target.value);
        // Validate phone
        setPhoneValid(valid);
    }

    return (
        <div className={classes.paper}>
            <div className={classes.form}>
                <Typography variant="subtitle1"
                            align={'center'}
                            className={'streamTheme'}
                            color={'textSecondary'}>
                    Щоб розпочати перегляд, напишіть нижче номер телефону, який Ви вказували під час реєстрації
                </Typography>
                <PhoneInput
                    variant="outlined"
                    required
                    fullWidth
                    label="Номер телефону"
                    margin="normal"
                    autoFocus
                    value={phone}
                    onKeyPress={e => e.key === 'Enter' && handleSubmit()}
                    onChange={(event, valid) =>
                        handlePhoneChange(event, valid)}
                />
                <Button
                    disabled={!phoneValid}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleSubmit}
                    endIcon={<FontAwesomeIcon color={'inherit'} icon={faLongArrowAltRight}/>}
                >
                    Приєднатися до вебінару
                </Button>

                <Typography variant="subtitle1"
                            align={'center'}
                            className={'streamTheme'}
                            color={'textSecondary'}>
                    <span>
                        Звертаємо Вашу увагу, за одним зареєстрованим номером
                        до заходу може долучитися один користувач
                    </span>
                </Typography>

                <Typography variant="subtitle2"
                            align={'center'}
                            className={'streamTheme'}
                            color={'textSecondary'}>
                    Контакти спеціалістів технічної підтримки: {' '}
                    <a className={classes.link} target={'_blank'} href={'https://t.me/punktriton'}>
                        <span>Telegram</span>{' '}
                        <FontAwesomeIcon icon={faExternalLinkAlt}/>
                    </a>{' '}та
                    <a className={classes.link} target={'_blank'} href={'viber://chat?number=380664316712'}>
                        <span>Viber</span>{' '}
                        <FontAwesomeIcon icon={faExternalLinkAlt}/>
                    </a>
                </Typography>
            </div>
        </div>
    );
}

export default JoinStreamAuthForm;
