import {useEffect} from 'react';
import { connect } from 'react-redux';
import './OnlineStream.scss';

import { buildXhrSetupConfig } from '../../../Util';
import { fetchStreamByViewKey } from '../../../redux/viewer/streams/actions';
import { getStreamFetchStatus, getStream } from '../../../redux/viewer/streams/selectors';
import { ERROR, LOADING, UNLOADED } from '../../../constants';
import { getAccessToken } from '../../../redux/auth/selectors';
import Chat from '../../../Components/Organisms/Chat/Chat';
import { makeStyles } from '@material-ui/core/styles';
import Error from '../../../Components/Molecules/Error/Error';
import { getStreamFetchError } from '../../../redux/admin/streams/selectors';
import VideoPlayer from '../../../Components/Organisms/VideoPlayer/VideoPlayer';
import ReactPlayer from 'react-player';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',

        [theme.breakpoints.down('xl')]: {
            maxHeight: '800px'
        },

        [theme.breakpoints.down('lg')]: {
            maxHeight: '650px'
        },

        [theme.breakpoints.down('md')]: {
            maxHeight: '500px'
        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            maxHeight: '100%'
        },
    },
    player: {
        flexBasis: '70%',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
        },
        display: 'flex',
        flexDirection: 'column'
    },
    chat: {
        flexBasis: '25%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%'
            // marginTop: theme.spacing(1)
        }
    }
}));

function OnlineStream(props) {
    const {streamKey, streamStatus, stream} = props;

    const classes = useStyles();

    useEffect(() => {
        props.fetchStreamByViewKey(streamKey);
    }, []);

    if (streamStatus === UNLOADED || streamStatus === LOADING) {
        return null;
    } else if (streamStatus === ERROR) {
        return <Error message={props.streamError}/>;
    }

    const renderInternalPlayback = playback => (
        <VideoPlayer
            autoplay={true}
            controls={true}
            fluid={true}
            // beforeXhrRequest={(options) => buildXhrSetupConfig(options, props.accessToken)}
            html5={{
                nativeAudioTracks: true,
                nativeVideoTracks: true,
                hls: {
                    overrideNative: false,
                }
            }}
            sources={[{
                src: `${playback.playlist}?access_token=${props.accessToken}`,
                type: 'application/x-mpegURL',
                // src: 'https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8',
                // type: 'video/mp4'
            }]}
        />
    );

    const renderYoutubePlayback = playback => (
        <ReactPlayer
            // url={`${stream.playlist}?access_token=${props.accessToken}`}
            url={`https://www.youtube.com/watch?v=${playback.videoKey}`}
            autoPlay={true}
            controls={true}
            width="100%"
            style={{display: 'flex', flexGrow: 1}}
        />
    );

    const renderPlayback = playback => {
        switch (playback.type) {
            case 'youtube':
                return renderYoutubePlayback(playback);
            case 'internal':
                return renderInternalPlayback(playback);
        }

        return <Error message={'Зверністься до підтримки'}/>;
    }

    return (
        <div>
            <div className={classes.container}>
                <div className={classes.player}>
                    {renderPlayback(stream.playback)}
                </div>
                <div className={classes.chat}>
                    <Chat
                        streamKey={streamKey}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    streamStatus: getStreamFetchStatus(state),
    accessToken: getAccessToken(state),
    stream: getStream(state),
    streamError: getStreamFetchError(state),
});
const mapDispatchToProps = { fetchStreamByViewKey };

export default connect(mapStateToProps, mapDispatchToProps)(OnlineStream);
