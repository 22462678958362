import axios from 'axios';
import actionTypes from './actionTypes';
import { hideLoader, showLoader } from '../../ui/loader';
import { enqueueSnackbar } from '../../ui/notifications';
import { createErrorNotification, createSuccessNotification, getErrorMessage } from '../../../Util';

const API_URL = 'api/streams';

export const fetchStreamsSuccess = streams => dispatch => {
    dispatch({
        type: actionTypes.GET_STREAMS_SUCCESS,
        streams
    });
    dispatch(hideLoader());
}

export const fetchStreamsFailure = error => dispatch => {
    dispatch({
        type: actionTypes.GET_STREAMS_FAILURE,
        error
    });
    dispatch(enqueueSnackbar(createErrorNotification(error)));
}

export const createStreamSuccess = stream => dispatch => {
    dispatch({
        type: actionTypes.CREATE_STREAM_SUCCESS,
        stream
    });
    // Fetch streams after creating new one
    dispatch(fetchStreams());
    dispatch(enqueueSnackbar(createSuccessNotification('Стрім успішно створено!')))
}

export const createStreamFailure = error => dispatch => {
    dispatch({
        type: actionTypes.CREATE_STREAM_FAILURE,
        error
    });
    dispatch(enqueueSnackbar(createErrorNotification(error)));
    dispatch(hideLoader());
}

export const updateStreamSuccess = (stream) => dispatch => {
    dispatch({
        type: actionTypes.UPDATE_STREAM_SUCCESS,
        stream
    });
    // Fetch streams after creating new one
    dispatch(fetchStreams());
    dispatch(enqueueSnackbar(createSuccessNotification('Стрім успішно відредаговано!')));
}

export const updateStreamFailure = error => dispatch => {
    dispatch({
        type: actionTypes.UPDATE_STREAM_FAILURE,
        error
    });
    dispatch(enqueueSnackbar(createErrorNotification(error)));
    dispatch(hideLoader());
}

export const deleteStreamSuccess = (stream) => dispatch => {
    dispatch({
        type: actionTypes.DELETE_STREAM_SUCCESS,
        stream
    });
    // Fetch streams after creating new one
    dispatch(fetchStreams());
    dispatch(enqueueSnackbar(createSuccessNotification('Стрім успішно видалено!')));
}

export const deleteStreamFailure = error => dispatch => {
    dispatch({
        type: actionTypes.DELETE_STREAM_FAILURE,
        error
    });
    dispatch(enqueueSnackbar(createErrorNotification(error)));
    dispatch(hideLoader());

}

export const fetchStreamSuccess = stream => dispatch => {
    dispatch({
        type: actionTypes.GET_STREAM_SUCCESS,
        stream
    });
    dispatch(hideLoader());
}

export const fetchStreamFailure = error => dispatch => {
    dispatch({
        type: actionTypes.GET_STREAM_FAILURE,
        error
    });
    dispatch(enqueueSnackbar(createErrorNotification(error)));
    dispatch(hideLoader());
}

export const fetchStreams = () => dispatch => {
    dispatch({
        type: actionTypes.GET_STREAMS_REQUEST
    });
    dispatch(showLoader());

    return axios
        .get(API_URL)
        .then(res => dispatch(fetchStreamsSuccess(res.data)))
        .catch(error => dispatch(fetchStreamsFailure(getErrorMessage(error))));
};

export const createStream = (payload) => dispatch => {
    dispatch({
        type: actionTypes.CREATE_STREAM_REQUEST
    });
    dispatch(showLoader());

    axios
        .post(API_URL, payload)
        .then(response => dispatch(createStreamSuccess(response.data)))
        .catch(error => dispatch(createStreamFailure(getErrorMessage(error))));
};

export const updateStream = (id, payload) => dispatch => {
    dispatch({
        type: actionTypes.UPDATE_STREAM_REQUEST
    });
    dispatch(showLoader());

    return axios
        .put(`${API_URL}/${id}`, payload)
        .then(response => dispatch(updateStreamSuccess(response.data)))
        .catch(error => dispatch(updateStreamFailure(getErrorMessage(error))));
};

export const deleteStream = (id) => dispatch => {
    dispatch({
        type: actionTypes.DELETE_STREAM_REQUEST
    });
    dispatch(showLoader());

    axios
        .delete(`${API_URL}/${id}`)
        .then(response => dispatch(deleteStreamSuccess(response.data)))
        .catch(error => dispatch(deleteStreamFailure(getErrorMessage(error))));
};

export const fetchStreamById = (id) => dispatch => {
    dispatch({
        type: actionTypes.GET_STREAM_REQUEST
    });
    dispatch(showLoader());

    axios
        .get(`${API_URL}/${id}`)
        .then(res => dispatch(fetchStreamSuccess(res.data)))
        .catch(error => dispatch(fetchStreamFailure(getErrorMessage(error))));
};
