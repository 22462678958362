import { createSelectorCreator } from '../../../Util';

const createScopedSelector = createSelectorCreator(
    state => state.admin.chat
);

export const getDeleteMessageStatus = createScopedSelector(
    state => state.deleteMessageStatus
);

export const getDeleteMessageError = createScopedSelector(
    state => state.deleteMessageError
);

export const getBanUserStatus = createScopedSelector(
    state => state.banUserStatus
);

export const getBanUserError = createScopedSelector(
    state => state.banUserError
);

export const getBannedViewers = createScopedSelector(
    state => state.bannedViewers
);

export const getBannedViewersError = createScopedSelector(
    state => state.bannedViewersError
);

export const getBannedViewersStatus = createScopedSelector(
    state => state.bannedViewersStatus
);
