import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {green, red} from '@material-ui/core/colors';
import BackupIcon from '@material-ui/icons/Backup';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '../../../../../../Components/Molecules/Modal/Modal';
import RetranslationSettingsEdit from './RetranslationSettingsEdit';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(3),
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    warningIcon: {
        color: red[500]
    },
    warningText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start'
    },
    avatar: {
        backgroundColor: green[500]
    }
}));

export default function RetranslationSettings(props) {
    const classes = useStyles();
    const [editModalOpen, setEditModalOpen] = useState(false);

    const { updateStream, stream } = props;

    const handleEditClick = () => {
        setEditModalOpen(true);
    }

    const handleEditSubmit = (data) => {
        updateStream(data);
        setEditModalOpen(false);
    }

    return (
        <>
            <Card className={classes.root}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="source" className={classes.avatar}>
                            <BackupIcon/>
                        </Avatar>
                    }
                    action={
                        <IconButton onClick={handleEditClick}>
                            <EditIcon/>
                        </IconButton>
                    }
                    title="Налаштування ретрансляції"
                    subheader={'Задайте налаштування ретрансляції'}
                />
                <CardContent>
                    <Typography variant="body1" color="textSecondary" component="p">
                        Адреса ретрансляції RTMP потоку.
                    </Typography>
                </CardContent>
            </Card>

            {/* Edit modal */}
            <Modal open={editModalOpen}
                   setOpen={setEditModalOpen}
                   title={'Редагувати налаштування ретрансляції'}
            >
                <RetranslationSettingsEdit data={stream} onSubmit={handleEditSubmit}/>
            </Modal>
        </>
    );
}
