import React, {useCallback, useEffect, useRef, useState} from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import {
    IconButton, ListItem, List, Divider, TextField, Grid, Paper, ListItemText, CircularProgress, Button, Popper, Fade
} from '@material-ui/core';

import { LOADED, LOADING, MESSAGE_SYMBOLS_LIMIT, MESSAGES_DIRECTION_UP } from '../../../constants';
import { getMessages, getMessagesFetchStatus, getSendMessageStatus } from '../../../redux/viewer/chat/selectors';
import {
    fetchMessagesByStreamViewKey,
    sendMessage,
    setChatStreamKey,
    fetchNextMessages,
    fetchPreviousMessages
} from '../../../redux/viewer/chat/actions';
import { isAdmin } from '../../../redux/auth/selectors';
import ChatMessageManager from '../ChatMessageManager/ChatMessageManager';
import { banUser, deleteMessage } from '../../../redux/admin/chat/actions';
import ChatUnbanModal from '../ChatUnbanModal/ChatUnbanModal';
import { Block } from '@material-ui/icons';

const UPDATE_INTERVAL = 3000;
const LOAD_PREVIOUS_THRESHOLD = 30;

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 300,
    },
    chatSection: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    headBG: {
        backgroundColor: '#e0e0e0'
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0'
    },
    messageArea: {
        height: 'auto',
        overflowY: 'auto',
        flexGrow: 1
    },
    grow: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    chatBody: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',

        [theme.breakpoints.down('sm')]: {
            maxHeight: '700px'
        }
    },
    youButton: {
        float: 'right'
    }
}));

const Message = (props) => {
    const { message, showAuthor, onAuthorClick } = props;
    const classes = useStyles();

    const handleAuthorClick = (event) => {
        if (onAuthorClick) {
            onAuthorClick(event);
        }
    }

    const align = message.you ? 'right' : 'left';

    return <ListItem key={message.id}>
        <Grid container>
            <Grid item xs={12}>
                <ListItemText align={align} primary={message.message}/>
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*    <ListItemText align={align} secondary={formatLocaleDate(message.createdAt)}/>*/}
            {/*</Grid>*/}
            {/* Show author button */}
            {showAuthor && !message.you && message.author && <Grid item xs={12}>
                <Button align={align} color={'textSecondary'} onClick={handleAuthorClick}>
                    {message.admin === true ? message.author.user.username : message.author.code}
                </Button>
            </Grid>}
            {/* Show you label */}
            {showAuthor && !!message.you && message.author && <Grid item xs={12}>
                <Button className={classes.youButton} align={align} color={'textSecondary'} onClick={handleAuthorClick}>
                    {'Ви'}
                </Button>
                {/*<ListItemText align={align} secondary={'Ви'}/>*/}
            </Grid>}

            {/* Show admin label */}
            {!showAuthor && !!message.admin && <Grid item xs={12}>
                <ListItemText align={align} secondary={'Адміністратор чату'}/>
            </Grid>}
        </Grid>
    </ListItem>;
}

const Chat = (props) => {
    const listRef = useRef(null);
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState(null);
    const [atBottom, setAtBottom] = useState(true);

    const [authorPopperOpen, setAuthorPopperOpen] = useState(false);
    const [authorPopperMessage, setAuthorPopperMessage] = useState(null);
    const [authorPopperAnchorEl, setAuthorPopperAnchorEl] = useState(null);

    const [openBannedList, setOpenBannedList] = useState(false);

    const classes = useStyles();
    const { messages, sendMessageStatus, fetchMessagesStatus, streamKey } = props;

    const isAtBottom = element => !!element && element.scrollHeight - element.scrollTop === element.clientHeight;
    const isNeedScroll = element => !!element && element.scrollHeight - element.scrollTop > element.clientHeight;
    const scrollValue = element => !!element && element.scrollHeight - element.clientHeight;

    if (atBottom && listRef.current && fetchMessagesStatus === LOADED) {
        isNeedScroll(listRef.current) && (listRef.current.scrollTop = scrollValue(listRef.current));
    }

    useEffect(() => {
        if (!atBottom && fetchMessagesStatus !== LOADING && fetchMessagesStatus === LOADED && listRef.current && listRef.current.scrollTop < LOAD_PREVIOUS_THRESHOLD) {
            props.fetchPreviousMessages();
            listRef.current.scrollTop = 1;
        }
    }, [listRef.current?.scrollTop, fetchMessagesStatus]);

    const onScroll = useCallback(
        event => {
            const bottom = isAtBottom(event.target);
            setAtBottom(prev => bottom);
        },
        [setAtBottom]
    );

    useEffect(() => {
        props.setChatStreamKey(streamKey);
        props.fetchMessagesByStreamViewKey(MESSAGES_DIRECTION_UP, new Date());

        const interval = setInterval(() => props.fetchNextMessages(), UPDATE_INTERVAL);

        return () => {
            clearInterval(interval);
        }
    }, []);

    const isMessageLimitExceed = () => message.length > MESSAGE_SYMBOLS_LIMIT;

    const handleMessageChange = useCallback(
        event => {
            const value = event.target.value;
            setMessage(value);
            setMessageError(null);

            if (isMessageLimitExceed()) {
                setMessageError(`Перевищено кількість дозволених символів: ${MESSAGE_SYMBOLS_LIMIT}`);
            }
        },
        [setMessage,setMessageError]
    );

    const handleSubmit = () => {
        if (message && message.length > 0 && !messageError) {
            if (message.trim().length === 0) {
                setMessage('');
                return;
            }

            setMessage('');
            setMessageError(null);
            props.sendMessage({ message });
        }
    }

    const renderSendButton = () => {
        const disabled = !message || message.length === 0 || !!messageError;
        const loading = sendMessageStatus === LOADING;

        if (loading) {
            return <CircularProgress/>;
        }

        return <IconButton color="primary" aria-label="add"
                           disabled={disabled}
                           onClick={handleSubmit}
        >
            <SendIcon/>
        </IconButton>;
    }

    const onMessageAuthorClick = (event, message) => {
        setAuthorPopperAnchorEl(event.currentTarget);
        setAuthorPopperOpen((prev) => (authorPopperMessage && authorPopperMessage.id !== message.id) || !prev);
        setAuthorPopperMessage(message);
    }

    const handleMessageDelete = () => {
        props.deleteMessage(streamKey, authorPopperMessage.id);
        setAuthorPopperOpen(false);
    }

    const handleAuthorBan = () => {
        if (!authorPopperMessage || !authorPopperMessage.author.code) {
            return;
        }
        props.banUser(streamKey, {
            code: authorPopperMessage.author.code,
            unban: false
        });
        setAuthorPopperOpen(false);
    }

    const handleOpenBannedListClick = () => {
        setOpenBannedList(true);
    }

    const banAllowed = () => {
        return authorPopperMessage ? !authorPopperMessage.admin : false;
    }

    const renderMessages = () => {
        return messages.map(message =>
            <Message key={message.id}
                     message={message}
                     showAuthor={props.isAdmin}
                     onAuthorClick={(event) => onMessageAuthorClick(event, message)}
            />);
    }

    return (<>
            {/* Blocked viewers modal and button */}
            {props.isAdmin && <>
                <ChatUnbanModal openModal={openBannedList}
                                setOpenModal={setOpenBannedList}
                                streamKey={streamKey}/>
                <Button variant={'contained'}
                        color={'primary'}
                        endIcon={<Block/>}
                        onClick={handleOpenBannedListClick}>
                    Відкрити список заблокованих користувачів
                </Button>
            </>}

            <Grid container component={Paper} className={classes.chatSection}>
                {/* Message popper */}
                <Popper open={authorPopperOpen} anchorEl={authorPopperAnchorEl}
                        placement={'bottom'}
                        transition>
                    {({TransitionProps}) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                                <ChatMessageManager onMessageDelete={handleMessageDelete}
                                                    onAuthorBan={handleAuthorBan}
                                                    banAllowed={banAllowed()}
                                />
                            </Paper>
                        </Fade>
                    )}
                </Popper>
                <Grid item xs={12} className={classes.chatBody}>
                    <List
                        ref={listRef}
                        className={classes.messageArea}
                        onScroll={onScroll}
                    >
                        {renderMessages()}
                    </List>
                    <div className={classes.grow}/>
                    <Divider/>
                    <Grid container style={{padding: '20px'}}>
                        <Grid item xs={11}>
                            <TextField id="your-message"
                                       label="Поставте питання..."
                                       fullWidth
                                       maxLength={MESSAGE_SYMBOLS_LIMIT}
                                       multiline
                                       rows={1}
                                       rowsMax={3}
                                       value={message}
                                       onChange={handleMessageChange}
                                       onKeyPress={e => {
                                           if (e.key === 'Enter') {
                                               handleSubmit();
                                               e.preventDefault();
                                           }
                                       }}
                                       error={!!messageError}
                                       helperText={messageError}
                            />
                        </Grid>
                        <Grid item xs={1} align="right">
                            {renderSendButton()}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

const mapStateToProps = (state) => ({
    sendMessageStatus: getSendMessageStatus(state),
    fetchMessagesStatus: getMessagesFetchStatus(state),
    messages: getMessages(state),
    isAdmin: isAdmin(state)
});

const mapDispatchToProps = {
    sendMessage,
    fetchMessagesByStreamViewKey,
    setChatStreamKey,
    fetchNextMessages,
    fetchPreviousMessages,
    banUser,
    deleteMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
