import actionTypes from './actionTypes';
import { ERROR, LOADED, LOADING, UNLOADED } from '../../../constants/statuses';

const initialState = {
    streamsFetchStatus: UNLOADED,
    streamsFetchError: null,
    streams: [],

    createStreamStatus: UNLOADED,
    createStreamError: null,

    updateStreamStatus: UNLOADED,
    updateStreamError: null,

    deleteStreamStatus: UNLOADED,
    deleteStreamError: null,

    streamFetchStatus: UNLOADED,
    streamFetchError: null,
    stream: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_STREAMS_REQUEST:
            return {
                ...state,
                streamsFetchStatus: LOADING
            };
        case actionTypes.GET_STREAMS_FAILURE:
            return {
                ...state,
                streamsFetchStatus: ERROR,
                streamsFetchError: action.error
            };

        case actionTypes.GET_STREAMS_SUCCESS:
            return {
                ...state,
                streamsFetchStatus: LOADED,
                streams: action.streams
            };

        case actionTypes.CREATE_STREAM_REQUEST:
            return {
                ...state,
                createStreamStatus: LOADING
            };
        case actionTypes.CREATE_STREAM_FAILURE:
            return {
                ...state,
                createStreamStatus: ERROR
            };

        case actionTypes.CREATE_STREAM_SUCCESS:
            return {
                ...state,
                createStreamStatus: LOADED
            };

        case actionTypes.UPDATE_STREAM_REQUEST:
            return {
                ...state,
                updateStreamStatus: LOADING
            };
        case actionTypes.UPDATE_STREAM_FAILURE:
            return {
                ...state,
                updateStreamStatus: ERROR
            };

        case actionTypes.UPDATE_STREAM_SUCCESS:
            return {
                ...state,
                updateStreamStatus: LOADED
            };

        case actionTypes.DELETE_STREAM_REQUEST:
            return {
                ...state,
                deleteStreamStatus: LOADING
            };
        case actionTypes.DELETE_STREAM_FAILURE:
            return {
                ...state,
                deleteStreamStatus: ERROR
            };

        case actionTypes.DELETE_STREAM_SUCCESS:
            return {
                ...state,
                deleteStreamStatus: LOADED
            };
        case actionTypes.GET_STREAM_REQUEST:
            return {
                ...state,
                streamFetchStatus: LOADING
            };
        case actionTypes.GET_STREAM_FAILURE:
            return {
                ...state,
                streamFetchStatus: ERROR,
                streamFetchError: action.error
            };

        case actionTypes.GET_STREAM_SUCCESS:
            return {
                ...state,
                streamFetchStatus: LOADED,
                stream: action.stream
            };

        default:
            return state;
    }
};
