import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {Button, Grid, makeStyles, useTheme, Paper, Typography, IconButton} from '@material-ui/core';

import { getStream, getStreamFetchStatus } from '../../../redux/admin/streams/selectors';
import { fetchStreamById, updateStream, deleteStream } from '../../../redux/admin/streams/actions';
import { LOADED } from '../../../constants';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import CommonDetailTab from './Tabs/CommonDetailTab';
import StreamingDetailTab from './Tabs/StreamingDetailTab';
import RefreshIcon from '@material-ui/icons/Refresh';
import StreamSettingsDetailTab from './Tabs/StreamSettingsDetailTab/StreamSettingsDetailTab';

const useStyles = makeStyles((theme) => ({
    padding: {
        padding: theme.spacing(5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    actions: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    backButton: {
        marginBottom: theme.spacing(5)
    },
    topActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    links: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(5),
        alignItems: 'flex-end'
    },
    urlButton: {
        marginBottom: theme.spacing(1)
    },
    usersTable: {
        padding: theme.spacing(5)
    },
    tableName: {
        marginTop: theme.spacing(3)
    },
    enabledChip: {
        marginLeft: theme.spacing(1)
    },
    textValue: {
        color: theme.palette.grey[600]
    }
}));

const COMMON_TAB = 0;
const STREAMING_TAB = 1;
const SETTINGS_TAB = 2;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function StreamDetail(props) {
    const [tab, setTab] = useState(COMMON_TAB);

    const history = useHistory();
    const theme = useTheme();

    const {
        stream,
        fetchStatus,
    } = props;

    const { streamId } = useParams();

    const classes = useStyles();

    useEffect(() => {
        props.fetchStreamById(streamId);
    }, []);

    // Go back to streams page
    const handleBack = () => {
        history.push(`/admin/streams`);
    }

    const handleRefresh = () => {
        props.fetchStreamById(streamId);
    }

    const handleEdit = (payload) => {
        props.updateStream(streamId, payload)
            .then(() => handleRefresh());
    }

    const handleDelete = () => {
        props.deleteStream(streamId);
        handleBack();
    }

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleChangeTabIndex = (index) => {
        setTab(index);
    };

    const renderTabs = () => {
        return <div>
            <AppBar position="static" color="default">
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="Стрім"
                >
                    <Tab label="Загальна інформація про стрім" {...a11yProps(COMMON_TAB)} />
                    <Tab label="Стрімінг інформація" {...a11yProps(STREAMING_TAB)} />
                    <Tab label="налаштування стрімінгу" {...a11yProps(SETTINGS_TAB)} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={tab}
                onChangeIndex={handleChangeTabIndex}
            >
                <TabPanel value={tab} index={COMMON_TAB} dir={theme.direction}>
                    <CommonDetailTab stream={stream}
                                     handleEditClick={handleEdit}
                                     handleDeleteClick={handleDelete}/>
                </TabPanel>
                <TabPanel value={tab} index={STREAMING_TAB} dir={theme.direction}>
                    <StreamingDetailTab handleEditClick={handleEdit} stream={stream}/>
                </TabPanel>
                <TabPanel value={tab} index={SETTINGS_TAB} dir={theme.direction}>
                    <StreamSettingsDetailTab stream={stream} updateStream={handleEdit}/>
                </TabPanel>
            </SwipeableViews>
        </div>
    }

    return fetchStatus === LOADED && (<>
            <Paper className={classes.padding}>

                <div className={classes.topActions}>
                    <Button
                        variant="contained"
                        color="default"
                        startIcon={<ArrowBackIcon />}
                        onClick={handleBack}
                        className={classes.backButton}
                    >
                        Повернутись до списку
                    </Button>
                    <IconButton className={classes.margin} aria-label={'Оновити'}
                                onClick={handleRefresh}>
                        <RefreshIcon/>
                    </IconButton>
                </div>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant={'h5'} component={'h5'}>
                            Стрім{' '}'{stream.name}'
                        </Typography>
                        <br/>
                    </Grid>
                    <Grid item xs={12}>
                        {renderTabs()}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

const mapStateToProps = (state) => ({
    stream: getStream(state),
    fetchStatus: getStreamFetchStatus(state)
});
const mapDispatchToProps = {
    fetchStreamById, updateStream, deleteStream
};

export default connect(mapStateToProps, mapDispatchToProps)(StreamDetail);
