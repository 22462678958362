import { getStartsIn } from './date';

/**
 * Build xhr config
 * Add request header for auth
 *
 * @param options
 * @param authKey
 */
export const buildXhrSetupConfig = (options, authKey) => {
    options.headers = options.headers || {};
    options.headers.Authorization = `Bearer ${authKey}`;
    return options;
}

/**
 * Get the absolute stream URL
 *
 * @param key
 * @returns {string}
 */
export const getStreamUrl = (key) => {
    return `${window.location.origin.toString()}${getStreamPath(key)}`
}

/**
 * Get relative stream url
 *
 * @param key
 * @returns {string}
 */
export const getStreamPath = (key) => {
    return `/app/online-stream/${key}`;
}

export const getStreamStartsIn = (stream) => {
    return stream.startsAt && getStartsIn(new Date(stream.startsAt));
}
