import React, {useState} from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const RetranslationSettings = (props) => {
    const { onSubmit, data } = props;
    const [rtmpRelay, setRtmpRelay] = useState((data && data.rtmpRelay) || '');
    const [rtmpRelayError, setRtmpRelayError] = useState(null);

    const handleSubmit = () => {
        // Check if valid
        if (!validate(rtmpRelay)) {
            return;
        }

        const result = {
            ...data,
            rtmpRelay
        };
        if (onSubmit) {
            onSubmit(result);
        }
    }

    const isValidUrl = (value) => {
        try {
            new URL(String(value));
        } catch (e) {
            return false;
        }
        return true;
    };

    const validate = (value) => {
        if (!isValidUrl(value)) {
            setRtmpRelayError('Невірний формат посилання');
            return false;
        } else {
            setRtmpRelayError(null);
            return true;
        }
    }

    const handleFieldChange = (setValue, event) => {
        const value = event.target.value;
        setValue(value);
        validate(value);
    }

    return (
        <div>
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="rtmpRelay"
                label="Посилання"
                name="rtmpRelay"
                autoComplete="rtmpRelay"
                autoFocus
                value={rtmpRelay}
                onChange={(event) =>
                    handleFieldChange(setRtmpRelay, event)}
                error={!!rtmpRelayError}
                helperText={rtmpRelayError}
                placeholder={'rtmp://your-link'}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                Редагувати
            </Button>
        </div>
    );
}

export default RetranslationSettings;
