import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import './Streams.scss';

import { getStreams, getStreamsFetchError, getStreamsFetchStatus } from '../../../redux/admin/streams/selectors';
import {createStream, deleteStream, fetchStreams, updateStream} from '../../../redux/admin/streams/actions';
import { ERROR, LOADED } from '../../../constants';
import StreamsList from '../../../Components/Organisms/Streams/StreamsList/StreamsList';
import Error from '../../../Components/Molecules/Error/Error';

function Streams(props) {
    const { streamsFetchStatus, streamsFetchError, streams } = props;

    const history = useHistory();
    const match = useRouteMatch();

    useEffect(() => {
        props.fetchStreams();
    }, []);

    const handleCreate = (data) => {
        props.createStream(data);
    }

    const handleEdit = (data) => {
        const { id, ...payload } = data;

        props.updateStream(id, payload);
    }

    const handleDelete = (data) => {
        props.deleteStream(data.id);
    }

    const handleDetail = (data) => {
        history.push(`${match.url}/${data.id}`);
    }

    const handleRefresh = () => {
        props.fetchStreams();
    }

    if (streamsFetchStatus === ERROR) {
        return <Error message={streamsFetchError}/>;
    }

    return streamsFetchStatus === LOADED && (<StreamsList
        streams={streams}
        onHandleCreate={handleCreate}
        onHandleEdit={handleEdit}
        onHandleDelete={handleDelete}
        onHandleDetail={handleDetail}
        onHandleRefresh={handleRefresh}
    />);
}

const mapStateToProps = (state) => ({
    streams: getStreams(state),
    streamsFetchStatus: getStreamsFetchStatus(state),
    streamsFetchError: getStreamsFetchError(state),
});
const mapDispatchToProps = { fetchStreams, createStream, updateStream, deleteStream };

export default connect(mapStateToProps, mapDispatchToProps)(Streams);
