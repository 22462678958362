import actionTypes from './actionTypes';
import { ERROR, LOADED, LOADING, UNLOADED } from '../../../constants';

const initialState = {
    banUserStatus: UNLOADED,
    banUserError: null,

    deleteMessageStatus: UNLOADED,
    deleteMessageError: null,

    bannedViewers: [],
    bannedViewersStatus: UNLOADED,
    bannedViewersError: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DELETE_MESSAGE_REQUEST:
            return {
                ...state,
                deleteMessageStatus: LOADING
            };
        case actionTypes.DELETE_MESSAGE_FAILURE:
            return {
                ...state,
                deleteMessageStatus: ERROR,
                deleteMessageError: action.error
            };

        case actionTypes.DELETE_MESSAGE_SUCCESS:
            return {
                ...state,
                deleteMessageStatus: LOADED
            };

        case actionTypes.BAN_USER_REQUEST:
            return {
                ...state,
                banUserStatus: LOADING
            };
        case actionTypes.BAN_USER_FAILURE:
            return {
                ...state,
                banUserStatus: ERROR,
                banUserError: action.error
            };

        case actionTypes.BAN_USER_SUCCESS:
            return {
                ...state,
                banUserStatus: LOADED
            };

        case actionTypes.GET_BANNED_VIEWERS_REQUEST:
            return {
                ...state,
                bannedViewersStatus: LOADING
            };
        case actionTypes.GET_BANNED_VIEWERS_FAILURE:
            return {
                ...state,
                bannedViewersStatus: ERROR,
                bannedViewersError: action.error
            };

        case actionTypes.GET_BANNED_VIEWERS_SUCCESS:
            return {
                ...state,
                bannedViewersStatus: LOADED,
                bannedViewers: action.bannedViewers
            };

        default:
            return state;
    }
};
