import React from 'react';

import './Dashboard.scss';
import DefaultWelcome from '../../../Components/Molecules/DefaultWelcome/DefaultWelcome';

function Dashboard() {
    return (
        <DefaultWelcome/>
    );
}

export default Dashboard;
