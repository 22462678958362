import { createSelectorCreator } from '../../Util';
import { LOADED, ROLE_ADMIN } from '../../constants';

const createScopedSelector = createSelectorCreator(
    state => state.auth
);

export const getAuthenticatedUser = createScopedSelector(
    state => state.user
);

export const isAuthenticated = createScopedSelector(
    state => state.isAuthenticated
);

export const getUserStatus = createScopedSelector(
    state => state.userStatus
);

export const getUserError = createScopedSelector(
    state => state.accountError
);

export const getAccessToken = createScopedSelector(
    state => state.accessToken
);

export const isAdmin = createScopedSelector(
    state => state.isAuthenticated && state.userStatus === LOADED
            && state.user.roles && state.user.roles.includes(ROLE_ADMIN)
);
