import React, { useState, useCallback } from 'react';

import { isValidPhone} from '../../../../Util';
import { IconButton, Button } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import PhoneInput from '../../../Atoms/PhoneInput/PhoneInput';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%'
    },
    phone: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    actionButton: {
        margin: theme.spacing(2, 0, 0, 2)
    }
}));

const PHONE_DEFAULT = '+38';
let increment = 0;

const createNewPhoneData = () => ({
    id: String(increment++),
    phone: PHONE_DEFAULT,
    valid: false
});

const initialData = createNewPhoneData();

const CreateViewersForm = (props) => {
    const { onSubmit } = props;
    const [state, setState] = useState([initialData]);
    const submitEnabled = -1 === state.findIndex(phoneNumber => !phoneNumber.valid);

    const classes = useStyles();

    const handleSubmit = () => {
        const data = { codes: state.map(phoneNumber => phoneNumber.phone) };

        if (onSubmit && data.codes.length > 0) {
            onSubmit(data);
        }
    }

    const handlePhoneChange = useCallback(
        e => {
            const newPhone = e.target.value;
            const id = e.target.parentNode.parentNode.dataset.tempid;
            setState(prev =>
                prev.map(item => (item.id === id ? { ...item, phone: newPhone, valid: isValidPhone(newPhone) } : item))
            );
        },
        [setState]
    );

    const addPhoneNumber = () => {
        setState([...state, createNewPhoneData()]);
    }

    const removePhoneNumber = useCallback(
        (e, id) => {
            setState((prev) => prev.filter((item) => item.id !== id));
        },
        [setState]
    );

    const isLastPhoneNumber = index => index === state.length - 1;

    const renderPhoneFields = state.map((phoneNumber, index) => {
        return (
            <div
                key={phoneNumber.id}
                className={classes.phone}
            >
                <PhoneInput
                    data-tempid={phoneNumber.id}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={phoneNumber.phone}
                    onChange={handlePhoneChange}
                />
                {isLastPhoneNumber(index) ? (
                    <IconButton
                        className={classes.actionButton}
                        aria-label="add"
                        component="span"
                        onClick={addPhoneNumber}
                    >
                        <Add/>
                    </IconButton>
                ) : (
                    <IconButton
                        className={classes.actionButton}
                        aria-label="delete"
                        component="span"
                        onClick={e => removePhoneNumber(e, phoneNumber.id)}
                    >
                        <Delete/>
                    </IconButton>
                )}
            </div>);
    });

    return (
        <div className={classes.form}>
            {renderPhoneFields}

            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!submitEnabled}
            >
                Створити
            </Button>
        </div>
    );
}

export default CreateViewersForm;
