import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LaptopIcon from '@material-ui/icons/Laptop';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import { Link } from 'react-router-dom';
import ContactLink, { EMAIL_TYPE, PHONE_TYPE } from '../../Components/Atoms/ContactLink/ContactLink';
import SocialButtons from '../../Components/Molecules/SocialButtons/SocialButtons';

export const adminListItems = (
    <div>
        <Link to='/admin/dashboard' className={'white-link'}>
            <ListItem button>
                <ListItemIcon>
                    <DashboardIcon/>
                </ListItemIcon>
                <ListItemText primary="Панель керування"/>
            </ListItem>
        </Link>
        <Link to='/admin/streams' className={'white-link'}>
            <ListItem button>
                <ListItemIcon>
                    <LaptopIcon/>
                </ListItemIcon>
                <ListItemText primary="Стріми"/>
            </ListItem>
        </Link>
        <Link to='/admin/users' className={'white-link'}>
            <ListItem button>
                <ListItemIcon>
                    <PeopleIcon/>
                </ListItemIcon>
                <ListItemText primary="Користувачі"/>
            </ListItem>
        </Link>
        <Link to='/admin' className={'white-link'}>
            <ListItem button href={'/#'}>
                <ListItemIcon>
                    <BarChartIcon/>
                </ListItemIcon>
                <ListItemText primary="Статистика"/>
            </ListItem>
        </Link>
    </div>
);

export const simpleListItems = (<div>
    <ListItem button>
        <ContactLink value={'+38(067)333-91-87'} type={PHONE_TYPE}/>
    </ListItem>
    <ListItem button>
        <ContactLink value={'+38(099)333-91-87'} type={PHONE_TYPE}/>
    </ListItem>
    <ListItem button>
        <ContactLink value={'info@optima.school'} type={EMAIL_TYPE}/>
    </ListItem>
    <ListItem button>
        <ContactLink value={'tech@optima.school'} type={EMAIL_TYPE}/>
    </ListItem>
    <ListItem button>
        <SocialButtons color={'inherit'}/>
    </ListItem>
</div>);
