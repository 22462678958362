export const AUTH_TOKEN_KEY = 'auth-token';

export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_USER = 'ROLE_USER';

export const STREAM_READY_STATUS = 'READY';
export const STREAM_LIVE_STATUS = 'LIVE';

export const MESSAGES_DIRECTION_UP = 'up';
export const MESSAGES_DIRECTION_DOWN = 'down';

export const YOUTUBE = 'youtube';
