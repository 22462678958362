const actionTypes = {
    GET_VIEWERS_REQUEST: 'viewers/GET_VIEWERS_REQUEST',
    GET_VIEWERS_SUCCESS: 'viewers/GET_VIEWERS_SUCCESS',
    GET_VIEWERS_FAILURE: 'viewers/GET_VIEWERS_FAILURE',

    CREATE_VIEWER_REQUEST: 'viewers/CREATE_STREAM_REQUEST',
    CREATE_VIEWER_SUCCESS: 'viewers/CREATE_STREAM_SUCCESS',
    CREATE_VIEWER_FAILURE: 'viewers/CREATE_STREAM_FAILURE',

    DELETE_VIEWER_REQUEST: 'viewers/DELETE_VIEWER_REQUEST',
    DELETE_VIEWER_SUCCESS: 'viewers/DELETE_VIEWER_SUCCESS',
    DELETE_VIEWER_FAILURE: 'viewers/DELETE_VIEWER_FAILURE',
};

export default actionTypes;
