import React, { useState } from 'react';

import DataTable from '../../DataTable/DataTable';
import Modal from '../../../Molecules/Modal/Modal';
import ConfirmModal from '../../../Molecules/ConfirmModal/ConfirmModal';

import CreateViewersForm from '../CreateViewersForm/CreateViewersForm';

const columns = [
    {
        title: 'Номер телефону',
        field: 'code',
        // render: rowData => <FormControl fullWidth><Input value={rowData.code} id={rowData.id} /></FormControl>,
    },
    {title: 'Ввмікнений', field: 'enabled', type: 'boolean'},
];

function ViewersList(props) {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [deleteRow, setDeleteRow] = useState(null);

    const {
        viewers,
        onHandleCreate,
        onHandleDelete
    } = props;

    const onAdd = () => {
        setCreateModalOpen(true);
    }

    const onDelete = (row) => {
        setDeleteRow(row);
        setDeleteModalOpen(true);
    }

    const handleCreate = (data) => {
        onHandleCreate(data);
        setCreateModalOpen(false);
    }

    const handleDelete = () => {
        onHandleDelete(deleteRow);
        setDeleteModalOpen(false);
    }

    return (<div className={props.className || ''}>
            <DataTable
                tableType='Глядач'
                title={'Глядачі'}
                columns={columns}
                data={viewers}
                onNew={onAdd}
                onDelete={onDelete}
            />

            {/* Create modal */}
            <Modal open={createModalOpen}
                   setOpen={setCreateModalOpen}
                   title={'Додати глядачів'}
            >
                <CreateViewersForm onSubmit={handleCreate}/>
            </Modal>

            {/* Delete confirm modal */}
            <ConfirmModal open={deleteModalOpen}
                          setOpen={setDeleteModalOpen}
                          title={'Видалити глядача'}
                          description={'Ви впевнені, що хочете видалити цього глядача?'}
                          onSubmit={handleDelete}
            />
        </div>
    );
}

export default ViewersList;
