const actionTypes = {
    LOGIN_REQUEST: 'auth/LOGIN_REQUEST',
    LOGIN_SUCCESS: 'auth/LOGIN_SUCCESS',
    LOGIN_FAILURE: 'auth/LOGIN_FAILURE',

    GET_ACCOUNT_REQUEST: 'auth/GET_ACCOUNT_REQUEST',
    GET_ACCOUNT_SUCCESS: 'auth/GET_ACCOUNT_SUCCESS',
    GET_ACCOUNT_FAILURE: 'auth/GET_ACCOUNT_FAILURE',

    UPDATE_ACCESS_TOKEN: 'auth/UPDATE_ACCESS_TOKEN',

    LOGOUT: 'auth/LOGOUT',
    CLEAR_AUTH: 'auth/CLEAR_AUTH'
};

export default actionTypes;
