import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { isValidPhone, PHONE_MASK } from '../../../Util';
import InputMask from 'react-input-mask';

const PhoneInput = (props) => {
    const {value, onChange, onValidCheck, ...otherProps} = props;
    const [phoneError, setPhoneError] = useState(null);
    const [showError, setShowError] = useState(false);

    const validPhone = (value) => {
        // Validate phone field
        if (!isValidPhone(value)) {
            setPhoneError('Неправильний формат номер телефону');
            return false;
        } else {
            setPhoneError(null);
            return true;
        }
    }

    const handleFieldChange = (event) => {
        const valid = validPhone(event.target.value);
        onChange(event, valid);
    }

    return (
        <InputMask
            mask={PHONE_MASK}
            value={value}
            onChange={handleFieldChange}
            onBlur={() => setShowError(true)}
        >
            {(inputProps) => (
                <TextField
                    error={showError && !!phoneError}
                    helperText={(showError && !!phoneError) ? phoneError : null}
                    {...otherProps}
                    {...inputProps}
                />
                )}
        </InputMask>
    );
}

export default PhoneInput;
