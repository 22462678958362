import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css';
import App from './App';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import store from './store';
import { logout } from './redux/auth/actions';
import { history } from './store';
import { setupAxiosInterceptors } from './Util';

setupAxiosInterceptors(process.env.REACT_APP_API_URL, () => {
    store.dispatch(logout());
});

const theme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            light: '#43a6f5',
            main: '#1976d2',
            dark: '#055ab0'
        }
    }
});

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://0c7718eeb2514f37aae141b6af398948@o449095.ingest.sentry.io/5680687',
        // integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        // tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <App/>
                </ThemeProvider>
            </ConnectedRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
