import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import {Button, Chip, Grid, IconButton, makeStyles, Typography} from '@material-ui/core';

import {STREAM_LIVE_STATUS, STREAM_READY_STATUS, STREAM_WILL_START} from '../../../../constants';

import LinkIcon from '@material-ui/icons/CallMade';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DoneIcon from '@material-ui/icons/Done';

import { formatLocaleDate, getStreamPath, getStreamUrl } from '../../../../Util';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Modal from '../../../../Components/Molecules/Modal/Modal';
import EditStreamForm from '../../../../Components/Organisms/Streams/EditStreamForm/EditStreamForm';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    padding: {
        padding: theme.spacing(5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    actions: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    links: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(5),
        alignItems: 'flex-end'
    },
    urlButton: {
        marginBottom: theme.spacing(1)
    },
    enabledChip: {
        marginLeft: theme.spacing(1)
    },
    textValue: {
        color: theme.palette.grey[600]
    }
}));

function StreamingDetailTab(props) {
    const {
        stream,
        handleEditClick
    } = props;
    const [editModalOpen, setEditModalOpen] = useState(false);

    const classes = useStyles();

    const onEdit = () => {
        setEditModalOpen(true);
    }

    const handleEdit = (data) => {
        const {...payload } = data;
        handleEditClick(payload);
        setEditModalOpen(false);
    }

    const renderStatus = () => {
        let status = '';
        let color = 'default';
        switch (stream.status) {
            case STREAM_READY_STATUS:
                color = 'default';
                status = 'Готовий до трансляції';
                break;
            case STREAM_LIVE_STATUS:
                color = 'secondary';
                status = 'Онлайн';
                break;
            case STREAM_WILL_START:
                color = 'primary';
                status = 'Очікується на початок';
                break;
        }

        return <Chip clickable
                     className={classes.enabledChip}
                     label={status}
                     color={color}/>;
    }

    return <>
        <Grid container spacing={3}>

            {/* Stream info */}
            <Grid item xs={9}>
                <Typography variant={'subtitle1'}>
                    Адреса RTMP: <a className={classes.textValue}
                                    href={stream.rtmpUrl} target={'_blank'}>
                    {stream.rtmpUrl}
                </a>
                </Typography>
                <Typography variant={'subtitle1'}>
                    Ключ: <span className={classes.textValue}>{stream.key}</span>
                </Typography>
                <Typography variant={'subtitle1'}>
                    Статус: {renderStatus()}
                </Typography>
                <Typography variant={'subtitle1'}>
                    Ввімкнений:
                    <Chip clickable
                          className={classes.enabledChip}
                          label={stream.enabled === true ? 'Так' : 'Ні'}
                          color={stream.enabled === true ? 'primary' : 'secondary'}
                          onDelete={() => console.log('click enabled chip')}
                          deleteIcon={stream.enabled === true ? <DoneIcon/> : null}/>
                </Typography>
                <Typography variant={'subtitle1'}>
                    Дозволено запускати стрім для глядачів:
                    <Chip clickable
                          className={classes.enabledChip}
                          label={stream.allowed === true ? 'Так' : 'Ні'}
                          color={stream.allowed === true ? 'primary' : 'secondary'}
                          onDelete={() => console.log('click enabled chip')}
                          deleteIcon={stream.allowed === true ? <DoneIcon/> : null}/>
                </Typography>
                <Typography variant={'subtitle1'}>
                    Оновлений в: <span className={classes.textValue}>
                            {formatLocaleDate(stream.updatedAt)}
                        </span>
                </Typography>
                <Typography variant={'subtitle1'}>
                    Створений в: <span className={classes.textValue}>
                            {formatLocaleDate(stream.createdAt)}
                        </span>
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <div className={classes.actions}>
                    <IconButton className={classes.margin}
                                onClick={onEdit}>
                        <EditIcon/>
                    </IconButton>
                </div>
                <div className={classes.links}>
                    <Link target={'_blank'}
                          replace
                          to={getStreamPath(stream.viewKey)}
                          style={{textDecoration: 'none'}}>
                        <Button
                            variant="contained"
                            color="default"
                            endIcon={<LinkIcon/>}
                            className={classes.urlButton}
                        >
                            Відкрити стрім
                        </Button>
                    </Link>

                    <CopyToClipboard text={getStreamUrl(stream.viewKey)}>
                        <Button
                            variant="contained"
                            color="default"
                            endIcon={<FileCopyIcon/>}
                        >
                            Скопіювати URL у буфер
                        </Button>
                    </CopyToClipboard>
                </div>
            </Grid>
        </Grid>

        {/* Edit modal */}
        <Modal open={editModalOpen}
               setOpen={setEditModalOpen}
               title={'Редагувати стрім'}
        >
            <EditStreamForm onSubmit={handleEdit} data={stream}/>
        </Modal>
    </>;
}

export default StreamingDetailTab;
