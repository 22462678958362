import React from 'react';
import videojs from 'video.js';
import '@videojs/http-streaming';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import 'video.js/dist/video-js.css';

export default class VideoPlayer extends React.Component {
    componentDidMount() {
        const { beforeXhrRequest, ...playerProps } = this.props;
        // instantiate Video.js
        videojs.Hls.xhr.beforeRequest = beforeXhrRequest
        this.player = videojs(this.videoNode, playerProps);
        this.player.qualityLevels();
        this.player.hlsQualitySelector({
            displayCurrentQuality: true
        });
    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    }

    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    render() {
        return (
            <div>
                <video
                    className={'video-js vjs-big-play-centered'}
                    ref={ node => this.videoNode = node }
                />
            </div>
        )
    }
}
