import React from 'react';
import { AUTH_TOKEN_KEY } from '../../../../../../constants';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import BuildIcon from '@material-ui/icons/Build';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Button } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    downloadButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
    },
    root: {
        marginBottom: theme.spacing(3),
    },
    avatar: {
        backgroundColor: blue[500]
    }
}));

export default function TranscodingSettings(props) {
    const classes = useStyles();

    const { stream } = props;

    return (
        <>
            <Card className={classes.root}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="source" className={classes.avatar}>
                            <BuildIcon/>
                        </Avatar>
                    }
                    title="Транскодінг"
                />
                <CardContent>
                    <div className={classes.downloadButtons}>
                        <Button href={`${process.env.REACT_APP_API_URL}/resources/binaries/ffmpeg.exe`} target={'_blank'} variant={'contained'} endIcon={<GetAppIcon/>}>
                            Завантажити ffmpeg.exe
                        </Button>
                        <Button href={`${process.env.REACT_APP_API_URL}/api/streams/${stream.id}/transcoding/windows?access_token=${localStorage.getItem(AUTH_TOKEN_KEY)}`} target={'_blank'} variant={'contained'} endIcon={<GetAppIcon/>}>
                            Завантажити скрипт запуску
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </>
    );
}
