const actionTypes = {
    UPDATE_STREAM_INFO: 'viewer/streams/UPDATE_STREAM_INFO',

    GET_STREAM_REQUEST: 'viewer/streams/GET_STREAM_REQUEST',
    GET_STREAM_SUCCESS: 'viewer/streams/GET_STREAM_SUCCESS',
    GET_STREAM_FAILURE: 'viewer/streams/GET_STREAM_FAILURE',

    GET_STREAM_INFO_REQUEST: 'viewer/streams/GET_STREAM_INFO_REQUEST',
    GET_STREAM_INFO_SUCCESS: 'viewer/streams/GET_STREAM_INFO_SUCCESS',
    GET_STREAM_INFO_FAILURE: 'viewer/streams/GET_STREAM_INFO_FAILURE',
};

export default actionTypes;
