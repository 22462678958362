import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, Drawer, AppBar, Button, Hidden, isWidthUp } from '@material-ui/core';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { APP_NAME, LOGOUT, LOADED } from '../../../constants';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

import { Menu as MenuIcon, ExitToApp as ExitToAppIcon } from '@material-ui/icons';

import { logout } from '../../../redux/auth/actions';
import { isLoading } from '../../../redux/ui/loader';
import { getAuthenticatedUser, getUserStatus, isAuthenticated } from '../../../redux/auth/selectors';

import {adminListItems, simpleListItems} from '../menuItems';

import {identifyUserRoutePrefix, isAdmin} from '../../../Util';

import classnames from 'classnames';
import SocialButtons from '../../../Components/Molecules/SocialButtons/SocialButtons';
import ContactLink, { PHONE_TYPE, EMAIL_TYPE } from '../../../Components/Atoms/ContactLink/ContactLink';

import logo from '../../../assets/images/logo-w.png';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    app: {
        display: 'flex',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    toolbar: {
        ...theme.mixins.toolbar,
        paddingRight: 24, // keep right padding when drawer closed
        flexGrow: 1
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        // position: 'relative',
        whiteSpace: 'nowrap',
        paddingTop: 15,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        // height: '90vh',
        overflow: 'auto',
        padding: theme.spacing(3),
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    logo: {
        maxHeight: theme.spacing(6)
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    footer: {
        // width: '100%',
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor: theme.palette.primary.main,
        boxShadow: '-1px -2px 3px 0px rgba(0, 0, 0, 0.2)',
        color: 'white',
        zIndex: 1300
    },
    grow: {
        [theme.breakpoints.up('sm')]: {
            flexGrow: '1'
        }
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    closeMenuButton: {
        marginRight: 'auto',
        marginLeft: 0,
    },
    contacts: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    logoutButton: {
        marginLeft: theme.spacing(2)
    }
}));

const Copyright = () => {
    return (
        <Typography variant="body2" align={'center'}>
            {'Copyright © '}{APP_NAME}{' '}{new Date().getFullYear()}
        </Typography>
    );
}

const Contacts = () => {

    return (<>
        <SocialButtons color={'white'}/>
        <ContactLink value={'tech@optima.school'} type={EMAIL_TYPE}/>
    </>);
}

const ManagerContacts = () => {
    return <>
        {/*<Typography variant={'body1'}>*/}
        {/*    Managers:*/}
        {/*</Typography>*/}
        <ContactLink value={'+38 (067) 177-95-70'} type={PHONE_TYPE}/>
        <ContactLink value={'info@optima.school'} type={EMAIL_TYPE}/>
    </>;
}

function DefaultLayout(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const { window } = props;

    const isAuthenticatedAdmin =  isAuthenticated && props.userStatus === LOADED && isAdmin(props.user);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        props.logout();
    }

    const getMenuItems = () => {
        return isAuthenticatedAdmin ? adminListItems : simpleListItems;
    }

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>{getMenuItems()}</List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    const showDrawer = isWidthUp('sm', props.width) || isAuthenticatedAdmin;

    return (
        <div className={classes.root}>
            <div className={classes.app}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon/>
                        </IconButton>

                        <Link to={identifyUserRoutePrefix(props.user ? props.user.roles : [])}
                              className={classnames([classes.header, 'app-name'])}>
                            <img className={classes.logo} src={logo} alt={APP_NAME}/>
                        </Link>

                        <div className={classes.contacts}>
                            <Contacts/>
                        </div>

                        <div className={classes.grow}/>

                        <div className={classes.contacts}>
                            <ManagerContacts/>
                        </div>

                        {props.isAuthenticated && (
                            <Button endIcon={<ExitToAppIcon/>}
                                    onClick={handleLogout}
                                    color="inherit"
                                    className={classes.logoutButton}
                            >
                                {LOGOUT}
                            </Button>
                        )}
                    </Toolbar>

                    {/* Show loader */}
                    {props.loading === true && <LinearProgress color={'secondary'}/>}
                </AppBar>
                <nav className={showDrawer ? classes.drawer : ''} aria-label="drawer">
                    <Hidden smUp implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={classes.direction === 'rtl' ? 'right' : 'left'}
                            open={open}
                            onClose={handleDrawerClose}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    {showDrawer && <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>}
                </nav>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer}/>

                    <Container maxWidth={'xl'} className={classes.container}>
                        {props.children}
                    </Container>
                </main>
            </div>
            <footer className={classes.footer}>
                <Container maxWidth="sm">
                    <Copyright/>
                </Container>
            </footer>
        </div>
    );
}

const mapStateToProps = (state) => ({
    loading: isLoading(state),
    isAuthenticated: isAuthenticated(state),
    userStatus: getUserStatus(state),
    user: getAuthenticatedUser(state)
});

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
